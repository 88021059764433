import React, { useEffect } from "react";
import "../style/about.css";

export default function About(){
    useEffect(()=>{
        document.title = "درباره ی ما";
    },[]);
    return <section className="grid container rtl mar20 aboutSection">
        <div className="flex flexCol gap5">
            <h1 className="titrFont textSize1 textClr3 flex flexCenter">فروشگاه اینترنتی برن شاپ</h1>
            <h2 className="titrFont textSize2 textClr3 mar10">درباره ما</h2>
            <p className="faFont textSize4 textClr5 justifyText">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.</p>
            <hr className="customHr mar10" />
            <h2 className="titrFont textSize2 textClr3 mar10">مجوز ها</h2>
            <p className="faFont textSize4 textClr5 justifyText">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.</p>
            <hr className="customHr mar10 lastAboutHr" />
        </div>
        <div className="flex flexCol gap5 aboutLeftSideDiv">
            <h2 className="titrFont textSize2 textClr3 mar10">تماس با ما</h2>
            <p className="faFont textSize4 textClr5 address callLink"><a className="textClr5" target="_blank" href="https://goo.gl/maps/pQPHRZGqf26wtCdE8">مشهد - وکیل آباد</a></p>
            <p className="faFont textSize4 textClr5 phone callLink"><a href="tel:09158771678" className="textClr5">۰۹۱۵۸۷۷۱۶۷۸</a></p>
            <p className="faFont textSize4 textClr5 email callLink"><a href="mailto:addevs14@gmail.com" className="textClr5">addevs14@gmail.com</a></p>
            <hr className="customHr mar10" />
            <h2 className="titrFont textSize2 textClr3 mar10">شبکه های اجتماعی</h2>
            <div className="flex flexCenter gap10 mar15">
                <a href="https://www.instagram.com" className="socialLink hideText insta">instagram</a>
                <a href="https://www.telegram.me" className="socialLink hideText telegram">telegram</a>
                <a href="https://wa.me/09158771678" className="socialLink hideText whatsapp">telegram</a>
            </div>
        </div>

    </section>
}