import React, { useContext, useEffect, useState } from "react";
import Loading from "./Loading";
import { scrollToTop, showMsg, toPer } from "./Methods";
import CustomInput from "./CustomInput";
import CustomTextArea from "./CustomTextArea";
import { AppContext } from "../App";
import SubmitBtn from "./SubmitBtn";
export default function InsertCustomerInfo(props){
    
    const {cartData} = useContext(AppContext);
    const [savedCart, setSaveCart ] = cartData;
    const [isLoading,setIsLoading] = useState(true);
    const [userInfo,setUserInfo] = useState(props.userInfo ? {"username":props.userInfo.usn.replaceAll("//","@"),"name":"","phone":"","address":"","postcode":"","description":""} : {"username":"","name":"","phone":"","address":"","postcode":"","description":""});
    const [isSubmitBtnActive,setIsSubmitBtnActive] = useState(true);

    const {displayCartContext} = useContext(AppContext);
    const [displayCart,setDisplayCart ] = displayCartContext;

    useEffect(()=>{
        const cartBtn = document.querySelector(".cartBtn");
        const cartNumberNotif = document.querySelector(".cartNumberNotif");
        if(cartBtn){
            cartBtn.classList.add("hide");
            cartNumberNotif.classList.add("hide");
        }
        scrollToTop();
        if(props.userInfo)
        {
            const data = new FormData();
            data.append("usn",userInfo.username);
            data.append("psw",props.userInfo.psw);
            fetch("/api2/userInfo/", {
                method: "POST",
                body: data,
            })
            .then(res=>{
                if(res.status === 200){
                    return res.json();
                }else{
                    throw Error("عدم دسترسی به سرور");
                }
            })
            .then(result=>{
                if(result.error){
                    throw new Error(result.msg)
                }
                setIsLoading(false);
                setUserInfo(userInfo=>({...userInfo,"name":`${result.firstName} ${result.lastName}`,"phone":result.phone,"address":result.address,"postcode":result.postcode}))
            })
            .catch(err=>{
                setIsLoading(false);
                showMsg(err.message);
            });
        }else{
            setIsLoading(false);
        }
        return () => {
            if(cartBtn){
                cartBtn.classList.remove("hide");
                cartNumberNotif.classList.remove("hide");
            }
        }
    },[]);
    function setUserInfoHandler(prop, value){
        setUserInfo({ ...userInfo, [prop]: value });
    }
    function checkDataFunction(){
        if(!userInfo){
            showMsg("خطایی رخ داده است .صفحه را مجدد بارگزاری کنید.")
            return;
        }
        if(!userInfo.username){
            showMsg("لطفا ایمیل خود را وارد کنید.");
            return;
        }
        if(!userInfo.name){
            showMsg("لطفا نام خود را وارد کنید.");
            return;
        }
        if(!userInfo.phone){
            showMsg("لطفا شماره تماس خود را وارد کنید.");
            return;
        }
        if(!userInfo.address){
            showMsg("لطفا آدرس خود را وارد کنید.");
            return;
        }
        if(!userInfo.postcode){
            showMsg("لطفا کد پستی خود را وارد کنید.");
            return;
        }
        if(displayCart.number > 0){
            let idList = [];
            displayCart.list.forEach(element => {
                idList.push({
                    id:element.id,
                    subId:element.subId,
                    discount:element.discountPercent,
                    price:element.price,
                    number:element.number,
                })
            });
            setIsSubmitBtnActive(false);
            submitPurchase(idList);
        }else{
            props.changeMenuHandler("cartList");
        }
    }
    function saveToWaitList(code,price){
        let purchaseHistory = {};
        if(localStorage.getItem("tcs")){
            let purchaseText = localStorage.getItem("tcs");
            console.log(purchaseText);
            try {
                purchaseHistory = JSON.parse(purchaseText);
            } catch (error) {
                purchaseHistory = {};
            }
        }
        if(!purchaseHistory[code]){
            purchaseHistory[code] = {
                code,
                price
            };
            localStorage.setItem("crt","");
            localStorage.setItem("tcs",JSON.stringify(purchaseHistory));
        }
        setIsLoading(true);
        setSaveCart([]);
        setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        let purchaseForm = document.getElementById("purchaseForm");
        let priceInput = purchaseForm.querySelector(".price");
        let tcInput = purchaseForm.querySelector(".tc");
        priceInput.value= price;
        tcInput.value= code;
        purchaseForm.submit();
    }
    function submitPurchase(idList){
        const data = new FormData();
        data.append("list",JSON.stringify(idList));
        data.append("username",userInfo.username);
        data.append("name",userInfo.name);
        data.append("phone",userInfo.phone);
        data.append("address",userInfo.address);
        data.append("postcode",userInfo.postcode);
        data.append("description",userInfo.description);

        fetch("/api2/submitPurchase/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            setIsSubmitBtnActive(true);
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                if(result.isItemFinished || result.isPriceChange){
                    props.changeMenuHandler("cartList");
                }
                throw new Error(result.msg);
            }
            saveToWaitList(result.trackingCode , result.totalPrice);
        })
        .catch(err=>{
            let msg = err.message;
            if(msg.toLowerCase()==="failed to fetch"){
                msg = "عدم ارتباط با سرور";
            }
            showMsg(msg);
        });
    }
    if(isLoading || !displayCart){
        return <Loading />
    }
    return <section className="insertUserInfoSection rtl grid">
        <div className="rightSideDiv flex flexCol gap10 mar15">
            <button className="backToCart faFont textSize5 textClr5" onClick={()=>props.changeMenuHandler("cartList")}>برگشت به سبد</button>
            <h1 className="faFont textSize4 textClr5 flex flexCenter insertUserDataTitle">مشخصات خود را وارد کنید</h1>
            <div className="flex flexCol gap10 mar10">
                <CustomInput name="username" complete={"username"} type={"email"} value={userInfo.username} placeholder={"example@gmail.com"} onChange={setUserInfoHandler} label={"ایمیل"} hasToggleBtn={false} icon="username" isEnglish={true} readOnly={true}/>
                <CustomInput name="name" complete={"name"} type={"name"} value={userInfo.name} placeholder={"کوروش تهام"} onChange={setUserInfoHandler} label={"نام"} hasToggleBtn={false} icon="person" isEnglish={false}/>
                <CustomInput name="phone" complete={"phone"} type={"phone"} value={userInfo.phone} placeholder={"09120000000"} onChange={setUserInfoHandler} label={"شماره تماس"} hasToggleBtn={false} icon="phone" isEnglish={true}/>
                <CustomTextArea name="address" complete={"address"} type={"text"} value={userInfo.address} placeholder={"مشهد - خیابان احمدآباد"} onChange={setUserInfoHandler} label={"آدرس"} hasToggleBtn={false} icon="address" isEnglish={false}/>
                <CustomInput name="postcode" complete={"postcode"} type={"number"} value={userInfo.postcode} placeholder={"987654321"} onChange={setUserInfoHandler} label={"کد پستی"} hasToggleBtn={false} icon="postcode" isEnglish={true}/>
                <CustomTextArea name="description" complete={"description"} type={"text"} value={userInfo.description} placeholder={"اگر توضیحی برای محصول یا نحوه دریافت دارید اینجا بنویسید"} onChange={setUserInfoHandler} label={"توضیحات"} hasToggleBtn={false} icon="description" isEnglish={false}/>
            </div>
        </div>
        <div className="flex flexCol leftSideDiv priceReportDiv mar20">
            <p className="faFont textSize4 textClr3 flex justifyBetween">قیمت محصولات : <span>{toPer(displayCart.totalPrice.toLocaleString())} تومان</span></p> 
            <p className="faFont textSize4 textClr3 flex justifyBetween">تخفیف : <span>{toPer((displayCart.totalPrice - displayCart.finalPrice).toLocaleString())} تومان</span></p> 
            <p className="faFont textSize4 textClr3 flex justifyBetween">هزینه ارسال : <span>{toPer((+displayCart.tcPrice).toLocaleString())} تومان</span></p> 
            <p className="faFont textSize4 textClr3 flex justifyBetween">قیمت نهایی : <span>{toPer((+displayCart.finalPrice + +displayCart.tcPrice).toLocaleString())} تومان</span></p>    
            <div className="confirmCartDiv flex flexCol">
                <SubmitBtn class={"submitBtn faFont textSize4 flex flexCenter payBtn"} click={checkDataFunction} content={<button className="submitBtn faFont textSize4 flex flexCenter payBtn" ><span >پرداخت</span></button>} active={isSubmitBtnActive} loaderClass="blueFillLoader flex flexCenter"/>
            </div>
        </div>
        <form action="/submitPurchase" method="post" className="hide" id="purchaseForm">
            <input type="hidden" name="price" className="price"/>
            <input type="hidden" name="tc" className="tc"/>
        </form>
    </section>;
}