import React, { useEffect, useRef } from "react";
import "../style/covers.css";
import { Link } from "react-router-dom";

export default function Covers(prop){
    const coversData = prop.covers;
    const sliderTime = 5000;
    const sliderInterval = useRef(null);
    const sliderIndex = useRef(0);
    const isDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const conversImageDiv = useRef("");
    useEffect(()=>{
        conversImageDiv.current = document.getElementById("conversImageDiv");
        generateInterval();
        return () => clearInterval(sliderInterval.current);
    },[]);
    function nextSlide(){
        document.querySelectorAll(".dot")[sliderIndex.current].classList.remove("activeDot");
        sliderIndex.current = sliderIndex.current + 1;
        if(sliderIndex.current >= coversData.length){
            sliderIndex.current = 0;
        }
        conversImageDiv.current.scrollLeft = sliderIndex.current * conversImageDiv.current.offsetWidth;
        clearInterval(sliderInterval.current);
        document.querySelectorAll(".dot")[sliderIndex.current].classList.add("activeDot");
        generateInterval();
    }
    function preSlide(){
        document.querySelectorAll(".dot")[sliderIndex.current].classList.remove("activeDot");
        sliderIndex.current = sliderIndex.current - 1;
        if(sliderIndex.current < 0 ){
            sliderIndex.current = coversData.length - 1;
        }
        conversImageDiv.current.scrollLeft = sliderIndex.current * conversImageDiv.current.offsetWidth;
        clearInterval(sliderInterval.current);
        document.querySelectorAll(".dot")[sliderIndex.current].classList.add("activeDot");
        generateInterval();
    }
    function generateInterval (){
        sliderInterval.current = setInterval(() => {
            let activeDot = document.querySelectorAll(".dot")[sliderIndex.current];
            if(activeDot){
                activeDot.classList.remove("activeDot");
                sliderIndex.current = sliderIndex.current + 1;
                if(sliderIndex.current >= coversData.length){
                    sliderIndex.current = 0;
                }
                conversImageDiv.current.scrollLeft = sliderIndex.current * conversImageDiv.current.offsetWidth;
                document.querySelectorAll(".dot")[sliderIndex.current].classList.add("activeDot");
            }
        }, sliderTime);
    }
    function mouseIsDown(e) {
        e.preventDefault();
        isDown.current = true;
        startX.current = e.pageX - conversImageDiv.current.offsetLeft;
        scrollLeft.current = conversImageDiv.current.scrollLeft;
    }
    function touchStart(e){
        isDown.current = true;
        startX.current = e.changedTouches[0].pageX - conversImageDiv.current.offsetLeft;
        scrollLeft.current = conversImageDiv.current.scrollLeft;
    }
    function touchEnd(e){
        let endX = e.changedTouches[0].pageX - conversImageDiv.current.offsetLeft;
        if(Math.abs(endX - startX.current) > 35)
        {
            if(isDown.current){
                if(startX.current > endX){
                    nextSlide();
                }else{
                    preSlide();
                }
            }
            isDown.current = false;
        }else{
            e.target.dataset.active = true;
            e.target.click();
        }
    }
    function mouseUp(e) {
        let endX = e.pageX - conversImageDiv.current.offsetLeft;
        if(Math.abs(endX - startX.current) > 35)
        {
            if(isDown.current){
                if(startX.current > endX){
                    nextSlide();
                }else{
                    preSlide();
                }
            }
            isDown.current = false;
        }else{
            e.target.dataset.active = true;
            e.target.click();
        }
    }
    function linkClickHandler(e){
        if(!e.target.dataset.active) 
            e.preventDefault();
    }
    function goToSlide(index){
        document.querySelectorAll(".dot")[sliderIndex.current].classList.remove("activeDot");
        sliderIndex.current = index;
        conversImageDiv.current.scrollLeft = sliderIndex.current * conversImageDiv.current.offsetWidth;
        clearInterval(sliderInterval.current);
        document.querySelectorAll(".dot")[sliderIndex.current].classList.add("activeDot");
        generateInterval();
    }
    return <section className="coverSection flex" >
        <div className="conversImageDiv flex hideScrollBar" id="conversImageDiv">
    {
        coversData.map((item,index)=>{
            return <div className="coverItem" key={index}>
                <picture className="picElem">
                    <source draggable= "false"  media="(min-width: 968px)" srcSet={item["laptop"]} />
                    <source draggable= "false"  media="(min-width: 769px)" srcSet={item["tablet"]} />
                    <img draggable= "false" src={item["mobile"]} alt="پیشنهاد شگفت انگیز" className="silderImage imageFit"  loading="lazy" />
                </picture>
                <Link draggable= "false" to={item.link} className="hideText coverItemLink" data-active="" onClick={(e)=>{linkClickHandler(e)}} onMouseDown={(e) => mouseIsDown(e)} onMouseUp={(e)=>{mouseUp(e)}} onTouchStart={(e) => touchStart(e)} onTouchEnd={(e)=>touchEnd(e)}>مشاهده</Link>
            </div>
        })
    } 
    </div>
    <div className="dotsList flex">
    {
        coversData.map((item,index)=>{
            if(index === 0){
                return <span className="dot activeDot" key={index} onClick={()=>goToSlide(index)}></span>
            }
            return <span className="dot" key={index} onClick={()=>goToSlide(index)}></span>
        })
    } 
    </div>
    <div className="flex gap5 sliderBtnDiv">
        <button className="sliderBtn hideText preCoverBtn" onClick={()=>preSlide()}>pre</button>
        <button className="sliderBtn hideText nextCoverBtn" onClick={()=>nextSlide()}>next</button>
    </div>
    
    </section>
}