import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import BlogCover from "./BlogCover";
import "../style/allBlog.css";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop, shortText, toPer } from "./Methods";
export default function AllBlogs(){
    const [isLoading,setIsLoading] = useState(true);
    const [data , setData] = useState("");
    const [activeBlogList ,setActiveBlogList ] = useState("def");
    const [searchInput , setSearchInput ] = useState("");
    const navigate = useNavigate();

    useEffect(()=>{
        scrollToTop();
        document.title = "مجله برن شاپ";
        fetch("/api2/allBlogs/")
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            setData({
                "allBlogs" : result.all,
                "news" : result.news,
                "popular" : result.popular,
                "mostComment" : result.mostComment
            })
            setIsLoading(false);
        })
        .catch(err=>{
            setIsLoading(false);
        });
    },[]);
    if(isLoading){
        return <Loading />
    }
    if(!data.allBlogs || data.allBlogs.length === 0){
        return <div className="noResultDiv flex flexCol rtl container mar15 gap10">
            <h2 className="titrFont textSize1 textClr3 noResultTitle">پستی برای نمایش وجود ندارد.</h2>
            <Link to="/" className="faFont textSize5 textClr7 noResultLink">بازگشت به صفحه اصلی</Link>
        </div>
    }
    return <section className="flex flexCol rtl container mar10">
        <div className="blogsContent grid">
            <div className="flex flexCol rightSideBlogDiv">
                <BlogCover data={data.news}/>
                <div className="flex justifyBetween postHeaderDiv">
                    <div className="flex alignCenter gap5">
                        <button onClick={()=>setActiveBlogList("def")} className={activeBlogList==="def" ? "changeListBtn faFont textSize5 textClr3 active" : "changeListBtn faFont textSize5 textClr7"}>جدیدترین</button>
                        <button onClick={()=>setActiveBlogList("visit")} className={activeBlogList==="visit" ? "changeListBtn faFont textSize5 textClr3 active" : "changeListBtn faFont textSize5 textClr7"}>پر بازدید ترین</button>
                        <button onClick={()=>setActiveBlogList("cm")} className={activeBlogList==="cm" ? "changeListBtn faFont textSize5 textClr3 active" : "changeListBtn faFont textSize5 textClr7"}>بیشترین نظر</button>
                    </div>
                    <Link to="/blogs" className="faFont seeMore textSize5">مشاهده همه</Link>
                </div>
                {
                    activeBlogList === "def" ? <div className="grid blogGridList mar15 gap10">
                        {
                            data.allBlogs.map((item,index)=>{
                                return <div className="flex flexCol blogGridItem gap10" key={index}>
                                    <div className="gridInfoDiv grid">
                                        <img src={item.img} alt={item.name} className="blogGridImg fitImage" />
                                        <div className="flex flexCol gap10">
                                            <Link to={`/blog/${item.link}`} className="titrFont textSize3 textClr3">{ toPer(shortText(item.name.toLocaleString(),100))}</Link>
                                            <p className="faFont textSize6 textClr5 justifyText">{toPer(shortText(item.body.toLocaleString(),150))}</p>
                                        </div>
                                    </div>
                                    <div className="flex justifyBetween mar15 blogItemActionDiv">
                                        <div className="blogPropertyDiv flex alignCenter gap5">
                                            <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(item.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                            <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(item.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                            <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(item.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                                        </div>
                                        <Link to={`/blog/${item.link}`} className="faFont textSize6 seeMore">مشاهده</Link>
                                    </div>
                                </div>
                            })
                        }
                    </div> : ""
                }
                {
                    activeBlogList === "visit" ? <div className="grid blogGridList mar15 gap10">
                        {
                            data.popular.map((item,index)=>{
                                return <div className="flex flexCol blogGridItem gap10" key={index}>
                                    <div className="gridInfoDiv grid">
                                        <img src={item.img} alt={item.name} className="blogGridImg fitImage" />
                                        <div className="flex flexCol gap10">
                                            <Link to={`/blog/${item.link}`} className="titrFont textSize3 textClr3">{ toPer(shortText(item.name.toLocaleString(),100))}</Link>
                                            <p className="faFont textSize6 textClr5 justifyText">{toPer(shortText(item.body.toLocaleString(),150))}</p>
                                        </div>
                                    </div>
                                    <div className="flex justifyBetween mar15 blogItemActionDiv">
                                        <div className="blogPropertyDiv flex alignCenter gap5">
                                            <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(item.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                            <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(item.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                            <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(item.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                                        </div>
                                        <Link to={`/blog/${item.link}`} className="faFont textSize6 seeMore">مشاهده</Link>
                                    </div>
                                </div>
                            })
                        }
                    </div> : ""
                }
                {
                    activeBlogList === "cm" ? <div className="grid blogGridList mar15 gap10">
                        {
                            data.mostComment.map((item,index)=>{
                                return <div className="flex flexCol blogGridItem gap10" key={index}>
                                    <div className="gridInfoDiv grid">
                                        <img src={item.img} alt={item.name} className="blogGridImg fitImage" />
                                        <div className="flex flexCol gap10">
                                            <Link to={`/blog/${item.link}`} className="titrFont textSize3 textClr3">{ toPer(shortText(item.name.toLocaleString(),100))}</Link>
                                            <p className="faFont textSize6 textClr5 justifyText">{toPer(shortText(item.body.toLocaleString(),150))}</p>
                                        </div>
                                    </div>
                                    <div className="flex justifyBetween mar15 blogItemActionDiv">
                                        <div className="blogPropertyDiv flex alignCenter gap5">
                                            <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(item.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                            <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(item.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                            <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(item.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                                        </div>
                                        <Link to={`/blog/${item.link}`} className="faFont textSize6 seeMore">مشاهده</Link>
                                    </div>
                                </div>
                            })
                        }
                    </div> : ""
                }
            </div>
            <form action="/blogs" className="searchContainer flex flexCol">
                <h2 className="titrFont textSize2 textClr3">جستجو در وبلاگ</h2>
                <p className="faFont textSize5 textClr5 justifyText">برای جستجو در وبلاگ تگ مورد نظر یا کلمه را جستجو کنید</p>
                <input type="text" name="q" value={searchInput} onChange={(e)=>setSearchInput(e.target.value)} placeholder="جستجو کنید" className="faFont textSize5 customInput blogSearchInput"/>
                <button className="faFont textSize4 submitSearch">تایید</button>
            </form>
        </div>
    </section>
}