import React, { useEffect, useRef } from "react";
import { shortText, toPer } from "./Methods";
import { Link } from "react-router-dom";
export default function BlogCover(props){
    const activeIndex = useRef(0);
    const coverTimer = useRef(null);
    const data = [...props.data];
    useEffect(()=>{
        generateTimer();
        return ()=>{
            clearInterval(coverTimer.current);
        }
    },[]);
    
    function generateTimer(){
        coverTimer.current = setInterval(() => {
            let currentActiveDiv = document.querySelector(".activeBlogItem");
            if(currentActiveDiv){
                currentActiveDiv.classList.remove("activeBlogItem");
                document.querySelectorAll(".dot")[activeIndex.current].classList.remove("activeDot");
                activeIndex.current++ ;
                if(activeIndex.current >= data.length){
                    activeIndex.current = 0;
                }
                const allBlogDivs = document.querySelectorAll(".blogItem");
                allBlogDivs[activeIndex.current].classList.add("activeBlogItem");
                document.querySelectorAll(".dot")[activeIndex.current].classList.add("activeDot");
            }
        }, 6000);
    }

    function goToItem(index){
        document.querySelectorAll(".blogItem")[activeIndex.current].classList.remove("activeBlogItem");
        document.querySelectorAll(".dot")[activeIndex.current].classList.remove("activeDot");
        activeIndex.current = index;
        document.querySelectorAll(".blogItem")[activeIndex.current].classList.add("activeBlogItem");
        document.querySelectorAll(".dot")[activeIndex.current].classList.add("activeDot");
        clearInterval(coverTimer.current);
        generateTimer();
    }
    return <section className="flex flexCol blogCovers mar15">
        <div className="flex">
            {
                data.map((item,index)=>{
                    return <div className={0 === index ? "blogItem gap15 activeBlogItem" : "blogItem  gap15"} key={index}>
                        <img src={item.img} alt={item.name} className="fitImage blogItemImage"/>
                        <div className="blogInfoDiv flex flexCol gap5">
                            <Link to={`/blog/${item.link}`} className="blogItemTitle titrFont textSize1 textClr3 justifyText">{ toPer(shortText(item.name.toLocaleString(),100))}</Link>
                            <p className="blogShortDescription faFont textSize5 textClr5 justifyText">{toPer(shortText(item.body.toLocaleString(),250))}</p>
                            <div className="flex justifyBetween mar15">
                                <div className="blogPropertyDiv flex alignCenter gap5">
                                    <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(item.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                    <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(item.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                    <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(item.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                                </div>
                                <Link to={`/blog/${item.link}`} className="faFont textSize5 seeMore">مشاهده</Link>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
        <div className="blogCoverActionDiv flex justifyBetween mar20">
            <div className="flex flexAlign actionBtnDiv">
                <button className="nextCoverBtn sliderBtn hideText" onClick={()=>goToItem(activeIndex.current + 1 < data.length ? activeIndex.current + 1 : 0)}>next</button>
                <button className="preCoverBtn sliderBtn hideText" onClick={()=>goToItem(activeIndex.current > 0 ? activeIndex.current - 1 : data.length - 1 )}>pre</button>
            </div>
            <div className="toggleDotList flex gap5 alignCenter">
                {
                    data.map((item,index)=>{
                        return <button className={index === activeIndex.current ? "dot hideText activeDot" : "dot hideText"} key={index} onClick={()=>goToItem(index)}></button>
                    })
                }
            </div>
        </div>
    </section>
}