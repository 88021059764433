import React, { useEffect, useState } from "react";
import "../style/faq.css";
import Loading from "./Loading";
import { showMsg, toPer } from "./Methods";
export default function Faq(){
    const [answerList , setAnswerList] = useState("");
    useEffect(()=>{
        document.title = "پرسش های متدوال";
    },[]);
    useEffect(()=>{
        fetch("/api2/faq/")
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw Error("عدم دسترسی به سرور");
            }
            setAnswerList(JSON.parse(result.list));
        })
        .catch(err=>{
            showMsg(err.message);
        });
    },[]);
    return <div className="container faqSection rtl">
        {
            answerList ? 
            answerList.length > 0 ?
            <div className="flex flexCol">
                <h1 className="titrFont textSize1 textClr3 flex flexCenter mar15">پرسش های متدوال</h1>
                <div className="flex flexCol qustionList mar20 gap15">
                {
                    answerList.map((item,index)=>{
                        return <div className="flex flexCol quesitonItemDiv" key={index}>
                            <label htmlFor={"questionInput"+index} className="faFont textSize4 textClr3 questionLabel">{toPer(item.question.toLocaleString())}</label>
                            <input type="checkbox" className="questionInput" name={"questionInput"+index} id={"questionInput"+index}/>
                            <p className="faFont textSize4 textClr3 answer">{toPer(item.answer.toLocaleString())}</p>
                        </div>
                    })
                }
                    
                        
                </div>
            </div>
            : 
            <div className="flex flexCol noResult">
                <h2 className="titrFont textSize2 textClr3 noResultText">نتیجه ای برای این بخش یافت نشد.</h2>
            </div>
            : <Loading />
        }
        
    </div>
}