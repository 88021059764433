import React, { useContext, useState } from 'react';
import '../style/header.css';
import { AppContext } from '../App';
import { Link, useNavigate } from 'react-router-dom';
import useFetch from './useFetch';
import { useEffect } from 'react';
import { showMsg, toPer } from './Methods';
import Loading from './Loading';
import CartItem from './CartItem';
import SoldOutItem from './SoldOutItem';

export default function Header(){
    const {data,error,isLoading} = useFetch("/api2/header/");
    const {isLogged} = useContext(AppContext);
    const {displayCartContext} = useContext(AppContext);
    const [displayCart, setDisplayCart ] = displayCartContext;
    const [userInfo,setUserInfo] = isLogged;
    const [navigationBar,setNavigationBar] = useState(false);
    const [mSearchQuery , setMSearchQuery] = useState("");
    const [categoriesList,setCategoriesList] = useState([]);
    const [mobileCategoryList,setMobileCategoryList] = useState([]);
    const [cartListData,setCartListData] = useState("");
    const navigate = useNavigate();

    const emptyCartElem = <div className="flex flexCol flexCenter emptyCartDiv">
        <span className="emptyCartImage hideText">empty cart</span>
        <p className="titrFont textSize2 textClr3">سبد خرید شما خالی است.</p>
    </div>;
    useEffect(()=>{
        if (userInfo && userInfo.usn && userInfo.psw) {
            const data = new FormData();
            data.append("usn",userInfo.usn.replaceAll("//","@"));
            data.append("psw",userInfo.psw);
            fetch("/api2/checkUser/", {
                method: "POST",
                body: data,
            })
            .then(res=>{
                if(res.status === 200){
                    return res.json();
                }else{
                    throw Error("عدم دسترسی به سرور");
                }
            })
            .then(result=>{
                if(result.error){
                    throw new Error(result.msg)
                }
            })
            .catch(err=>{
                localStorage.removeItem("usn");
                localStorage.removeItem("psw");
                showMsg(err.message);
                setUserInfo(null);
            });
        } else {
            setUserInfo(null);
        }
    },[]);
    useEffect(()=>{
        if(data){
            let catNames = JSON.parse(data.catNames);
            let subCats = JSON.parse(data.subCats);
            let tempList = [];
            let tempMobileList = [];
            let idCounter = 0;
            let mobileCounter = 0;
            for (let index = 0; index < catNames.length; index++) {
                tempList.push(<Link to={"/category/"+catNames[index]} className="faFont textSize4 textClr3 catItem" key={idCounter++}>{catNames[index]}</Link>);
                tempList.push(
                    <div className={index === 0 ? "flex catDetailsDiv activeFlex catDetailsDivGrey":"flex catDetailsDiv"} key={idCounter++}>
                        <div className="flex">
                            {
                                JSON.parse(subCats[index]).map((item,i)=>{
                                    if(item!=="1")
                                        return <Link to={"/category/?q=cat&catName="+catNames[index]+"&subCat="+item} className="faFont textSize5 textClr5 subLinkItem" key={i}>{item}</Link>
                                })
                            }
                            <Link to={"/category/?q=cat&catName="+catNames[index]} className="faFont textSize5 textClr5 subLinkItem" key={1000}>مشاهده همه موارد</Link>
                        </div>
                    </div>
                );
                tempMobileList.push(<div className="catParentDiv flex flexCol" key={mobileCounter}>
                    <label htmlFor={"catName" + mobileCounter} className="faFont textSize4 textClr3 catLabel">{catNames[index]}</label>
                    <input type="checkbox" name="catName" id={"catName" + mobileCounter} className="catNameInput hide"></input>
                    <div className="subCatList flex flexCol">
                    {
                        JSON.parse(subCats[index]).map((item,i)=>{
                            if(item!=="1")
                                return <Link to={"/category/?q=cat&catName="+catNames[index]+"&subCat="+item} onClick={()=>setNavigationBar(false)} className="faFont textSize5 textClr5 subLink flex alignCenter" key={i}>{item}</Link>
                        })
                    }
                        <Link to={"/category/?q=cat&catName="+catNames[index]} onClick={()=>setNavigationBar(false)} className="faFont textSize5 textClr5 subLink flex alignCenter" key={1000}>مشاهده همه ی موارد دسته</Link>
                    </div>
                </div>);
                mobileCounter++;
            }
            setCategoriesList(tempList);
            setMobileCategoryList(tempMobileList);
        }
    },[data]);
    
    function inputChangeListener(e){
        if(e.key === "Enter"){
            setMSearchQuery("");
            navigate(`search/?q=${mSearchQuery}`);
        }
    }
    function formSubmitHandler(e){
        e.preventDefault();
    }
    
    return (<>
            <header className="container flex justifyBetween row1 rtl">
                <div className="headerbg"></div>
                <div className="headerIcon flex alignCenter gap5">
                    <div className="footerActionDiv"></div>
                    <button className='menuBtn btn hideText' onClick={ ()=> setNavigationBar(!navigationBar)}>menu</button>
                    <Link className='btn cartBtn hideText' to='/cart'>cart</Link>
                    <div className="flex flexCol headerCartDiv">
                        <div className="flex justifyBetween">
                            <p className="titrFont textSize2 textClr3">سبد خرید</p>
                            <p className="faFont textSize5 textClr5 cartNumber">{displayCart && displayCart.number > 0 ? `${toPer(displayCart.number.toLocaleString())} کالا` : ""}</p>
                        </div>
                        <div className="flex flexCol cartDetailsList">
                            {
                                displayCart ? displayCart.err ? <p className="faFont flex flexCenter textSize4 textClr5" style={{height:"100%"}}>خطا در دریافت اطلاعات سبد خرید</p> : 
                                <div>
                                    {
                                        displayCart.number > 0 ?
                                        <div className="flex flexCol cartList gap10">
                                            {
                                                displayCart.list.map((item,index)=>{
                                                    return <CartItem data={item} key={index} />
                                                })
                                            }
                                        </div>
                                        :
                                        emptyCartElem
                                    }
                                    {
                                        displayCart.soldOut.length > 0 ?
                                        <div className="flex flexCol soldOutList">
                                            <h2 className="titrFont textSize3 soldOutTitle flex flexCenter">آیتم های زیر تمام شده است.</h2>
                                            {
                                                displayCart.soldOut.map((item,index)=>{
                                                    return <SoldOutItem data={item} key={index} />
                                                })
                                            }
                                        </div>
                                        :""
                                    }
                                </div>
                                :
                                <Loading/>
                            }
                        </div>
                        <Link className="faFont textSize5 seeCart flex flexCenter" to="/cart">مشاهده سبد خرید</Link>
                    </div>
                    <Link className='btn userBtn hideText mainUserBtn' to={userInfo?'/user':"/login"}>cart</Link>
                    <p className={displayCart && displayCart.number > 0 ? "faFont textSize5 cartNumberNotif flex flexCenter" : ""}>{displayCart.number > 0 ? toPer(displayCart.number.toLocaleString()) : ""}</p>
                    <form action="/search" method="get" className='mainSearchDiv rtl' onSubmit={(e)=>formSubmitHandler(e)}>
                        <input className='faFont textSize5 textClr5 customInput mainSearchInput' placeholder='جستجو محصول ...' type="text" value={mSearchQuery} onChange={(e)=>setMSearchQuery(e.target.value)} onKeyUp={(e)=>inputChangeListener(e)}/>
                        <Link className='mainSearchLink faFont textSize5 textClr7 flex flexCenter' to={`/search/?q=${mSearchQuery}`} onClick={()=>setMSearchQuery("")}>جستجو</Link>
                    </form>
                </div>
                <Link className='logo enFont textSize2 textClr3' to='/'>BranCode</Link>
                <button className={navigationBar ? "navigationCloser active" : "navigationCloser" } onClick={ ()=> setNavigationBar(!navigationBar)}></button>
                <nav className={navigationBar ? "navigationBar flex flexCol active rtl gap5 customScrollBar":"navigationBar customScrollBar rtl flex flexCol gap5" }>
                    <button className="hideText btn closeNavBtn centerBg" onClick={ ()=> setNavigationBar(!navigationBar)}>close</button>
                    <hr className="customHr"></hr>
                    <Link to="/" onClick={()=>setNavigationBar(false)} className="faFont textSize5 rtl staticCat home textClr3 flex alignCenter">صفحه اصلی</Link>
                    <Link to="/category/?q=offers" onClick={()=>setNavigationBar(false)} className="faFont textSize5 rtl staticCat offer textClr3 flex alignCenter">پیشنهادات شگفت انگیز</Link>
                    <Link to="/blog" onClick={()=>setNavigationBar(false)} className="faFont textSize5 rtl staticCat blog textClr3 flex alignCenter">وبلاگ</Link>
                    <Link to="/tracking" onClick={()=>setNavigationBar(false)} className="faFont textSize5 rtl staticCat tracking textClr3 flex alignCenter">پیگیری سفارش</Link>
                    <Link to="/faq" onClick={()=>setNavigationBar(false)} className="faFont textSize5 rtl staticCat faq textClr3 flex alignCenter">پرسش های متدوال</Link>
                    <Link to="/about" onClick={()=>setNavigationBar(false)} className="faFont textSize5 rtl staticCat about textClr3 flex alignCenter">درباره ما</Link>
                    <hr className="customHr"></hr>
                    {
                        mobileCategoryList
                    }
                </nav>
            </header>
            <section className="container flex justifyBetween mobileSearchSection">
                <Link className='btn userBtn hideText' to={userInfo?'/user':"/login"}>cart</Link>
                <form action="/search" method="get" className='mobileSearchdiv rtl' onSubmit={(e)=>formSubmitHandler(e)}>
                    <input className='faFont textSize5 textClr5 customInput mSearchInput' name="q" placeholder='جستجو محصول ...' type="text" value={mSearchQuery} onChange={(e)=>setMSearchQuery(e.target.value)} onKeyUp={(e)=>inputChangeListener(e)}/>
                    <Link className='mobileSearchLink faFont textSize5 textClr7 flex flexCenter' to={`/search/?q=${mSearchQuery}`} onClick={()=>setMSearchQuery("")}>جستجو</Link>
                </form>
            </section>
            <section className="row3 container rtl">
                <button className="faFont textSize4 textClr5 showMainCat row3Item">دسته بندی ها</button>
                <div className="mainCatDiv">
                    <div className="mainCatList flex">
                        {categoriesList}
                    </div>
                </div>
                <Link to="/category/?q=offers" className="faFont textSize4 textClr5 offers row3Item">پیشنهادات شگفت انگیز</Link>
                <Link to="/blog" className="faFont textSize4 textClr5 blog row3Item">وبلاگ</Link>
                <Link to="/tracking" className="faFont textSize4 textClr5 tracking row3Item">پیگیری سفارش</Link>
                <Link to="/faq" className="faFont textSize4 textClr5 faq row3Item">پرسش های متدوال</Link>
                <Link to="/about" className="faFont textSize4 textClr5 about row3Item">درباره ما</Link>
            </section>
        </>
    );
}