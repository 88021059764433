import React, { useContext ,useEffect, useState} from "react";
import "../style/cart.css";
import { AppContext } from "../App";
import InCartMenu from "./InCartMenu";
import InsertCustomerInfo from "./InsertCustomerInfo";
export default function Cart(){
    const {isLogged} = useContext(AppContext);
    const [userInfo] = isLogged;
    const [isInCartList , setIsInCartList] = useState("cartList");

    function changeMenuHandler(newType){
        setIsInCartList(newType);
    }
    function storageEventHandler() {
        changeMenuHandler("cartList");
    }
    useEffect(()=>{
        document.title = "سبد خرید";
        window.addEventListener("storage", storageEventHandler);
        return () => {
            window.removeEventListener("storage", storageEventHandler);
        };
    },[]);
    
    return <section className="container flex flexCol">
    {
        isInCartList === "cartList" ? 
        <InCartMenu changeMenuHandler={changeMenuHandler} />
        :
        <InsertCustomerInfo changeMenuHandler={changeMenuHandler} userInfo={userInfo} />
    }
            
        
        
    </section>
}