import { useEffect, useState } from "react";

export default function useFetch(url,params){
    const [data,setData] = useState("");
    const [isLoading,setIsLoading] = useState(true);
    const [error,setError] = useState(null);

    useEffect(()=>{
        const data = new FormData();
        for (const key in params) {
            data.append(key,params[key]);
        }
        fetch(url, {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                let err = new Error(result.msg);
                err.code = result.code;
                throw err;
            }
            setData(result);
            setIsLoading(false);
        })
        .catch(err=>{
            setIsLoading(false);
            setError({msg:err.message,code:err.code});
        });
    },[])

    return {data,error,isLoading};
}