import React from "react";
import { Link } from "react-router-dom";
import "../style/links.css";
import { mouseIsDown, mouseLeave, mouseMove, mouseUp } from "./Methods";
export default function Links(prop){
    const data = prop.data;
    const defClass = prop.defClass;

    return <section className={"flex linkList hideScrollBar rtl gap10 " + defClass} onMouseDown={(e)=>mouseIsDown(e,"linkList")} onMouseMove={(e)=>mouseMove(e,"linkList")} onMouseLeave={(e)=>mouseLeave(e,"linkList")} onMouseUp={(e)=>mouseUp(e,"linkList")}>
        {data.map((item,index)=>{
            return <Link draggable="false" className="faFont textSize5 linkItem textClr5" key={index} to={item.link}>{item.catName}</Link>
        })}
    </section>
    
}