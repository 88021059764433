import React, { useContext, useEffect } from "react";
import Loading from "./Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import Links from "./Links";
import { generateRndClr, getProductInfo, scrollToTop, shortText, showMsg, timeInShamsi, toPer } from "./Methods";
import "../style/product.css";
import { useRef } from "react";
import { mouseIsDown, mouseLeave, mouseMove, mouseUp } from "./Methods";
import { useState } from "react";
import { AppContext } from "../App";
import ProductItem from "./ProductItem";
import ManageNumber from "./ManageNumber";
export default function Product(){
    const {id} = useParams();
    const [data,setData] = useState("");
    const [isLoading,setIsLoading] = useState(true);
    const [error,setError] = useState(null);

    const navigate = useNavigate();
    const {cartData} = useContext(AppContext);
    const {isLogged} = useContext(AppContext);
    const [userInfo, setUserInfo ] = isLogged;    
    const [savedCart, setSaveCart] = cartData;

    const {displayCartContext} = useContext(AppContext);
    const [displayCart , setDisplayCart ] = displayCartContext;
    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;
    let coverIndex = 0;
    const refInfo = useRef(
        {
            stocksData : "",
            prop1Name : "",
            prop2Name : "",
            commentList : [],
            prop1:"",
            prop2:"",
            commentCounter:0,
        }
    );

    const [stateInfo , setStateInfo] = useState({
        activeItem:"",
        stokeElem:"",
        commentsListElem : "",
        manageCartDiv :"",
    });
    
    let mobileImgsList = useRef("");

    useEffect(()=>{
        if(displayCart){
            setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        }
        document.body.style.paddingBottom = "100px";
        return () =>{
            document.body.style.paddingBottom = "0";
        }
    },[])
    useEffect(()=>{
        scrollToTop();
        document.title = "مشخصات و قیمت محصول ";

        const data = new FormData();
        data.append("id",id);
        fetch("/api2/product/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            refInfo.current= {
                stocksData : "",
                prop1Name : "",
                prop2Name : "",
                commentList : [],
                prop1:"",
                prop2:"",
                commentCounter:0,
            };
            setStateInfo({activeItem:"",
            stokeElem:"",
            commentsListElem : "",
            manageCartDiv:""});
            fetchComments();
            setData(result);
            setIsLoading(false);
        })
        .catch(err=>{
            setIsLoading(false);
            setError(err.message);
        });
    },[id]);
    
    useEffect(()=>{
        if(stateInfo.activeItem){
            // define propList 
            let prop1LabelElem = "";
            let prop1ListElem = [];
            if(stateInfo.activeItem.prop1){
                prop1LabelElem = <div className="flex gap5 alignCenter propLabelDiv">
                    <p className="faFont textSize5 textClr5">{toPer(refInfo.current.prop1Name.toLocaleString())}</p>
                    <p className="faFont textSize4 textClr5">:</p>
                    <p className="faFont textSize4 textClr3">{toPer(stateInfo.activeItem.prop1.toLocaleString())}</p>
                </div>
                let tempProp1List = {};
                refInfo.current.stocksData.map((item,index)=>{
                    if(!tempProp1List[item.prop1])
                    {
                        tempProp1List[item.prop1] = true;
                        prop1ListElem.push(<button onClick={()=>{prop1ChangeHandler(item.prop1,item.prop2)}} className={item.prop1 === stateInfo.activeItem.prop1 ?"faFont textSize4 textClr3 prop activeProp":"faFont textSize4 textClr3 prop"} key={index}>{toPer(item.prop1.toLocaleString())}</button>)
                    }
                });
                
            }
            let prop2Label = "";
            let prop2ListElem = [];
            if(stateInfo.activeItem.prop2){
                prop2Label = <div className="flex gap5 alignCenter propLabelDiv">
                    <p className="faFont textSize5 textClr5">{toPer(refInfo.current.prop2Name.toLocaleString())}</p>
                    <p className="faFont textSize4 textClr5">:</p>
                    <p className="faFont textSize4 textClr3">{toPer(stateInfo.activeItem.prop2.toLocaleString())}</p>
                </div>
                let tempProp2List = {};
                refInfo.current.stocksData.map((item,index)=>{
                    if(!tempProp2List[item.prop2])
                    {
                        tempProp2List[item.prop2] = true;
                        prop2ListElem.push(<button onClick={()=>{prop2ChangeHandler(item.prop2)}} className={item.prop2 === stateInfo.activeItem.prop2 ?"faFont textSize4 textClr3 prop activeProp":"faFont textSize4 textClr3 prop"} key={index}>{toPer(item.prop2.toLocaleString())}</button>)
                    }
                    
                });
            }
            // calculate product discount
            
            let realPrice = +stateInfo.activeItem.price;
            let discountPercent = +stateInfo.activeItem.discount;
            let discountPrice = realPrice - (discountPercent * realPrice / 100);
            let priceElem = "";
            let pricePropInfoElem = "";
            if(refInfo.current.prop1Name){
                let pricePropInfoText = refInfo.current.prop1Name + " : " + stateInfo.activeItem.prop1 ;
                if(refInfo.current.prop2Name){
                    pricePropInfoText += " - " + refInfo.current.prop2Name + " : " + stateInfo.activeItem.prop2 ;
                }
                pricePropInfoElem = <p className="faFont textSize5 textClr5 pricePropInfo">{toPer(pricePropInfoText.toLocaleString())}</p>
            }
            if(discountPercent > 0)
            {
                priceElem = <div className="flex flexCol priceElem">
                    <div className="discountDiv flex alignCenter gap10">
                        <p className="faFont textSize5 discountPercent rtl">{toPer(discountPercent.toLocaleString())}</p>
                        <p className="faFont textSize4 textClr9 discountPrice rtl">{toPer(realPrice.toLocaleString())}</p>
                    </div>
                    <p className="faFont textSize3 textClr3 finalDiscountPrice">{toPer(discountPrice.toLocaleString())} تومان</p>
                </div>
            }else{
                priceElem = <div className="flex flexCol priceElem">
                    <p className="faFont textSize3 textClr3 finalDiscountPrice">{toPer(realPrice.toLocaleString())} تومان</p>
                </div>
            }
            // check is product in cart
            let isInCart = savedCart[id + "-" + stateInfo.activeItem.id];
            let manageCartDiv = "";
            if(isInCart && stateInfo.activeItem.number > 0){
                if(isInCart.number > 0){
                    manageCartDiv = <ManageNumber number={savedCart[id + "-" + stateInfo.activeItem.id].number} max={stateInfo.activeItem.number} id={id} subId={stateInfo.activeItem.id} />;
                }else{
                    let tempPropText = "";
                    if(refInfo.current.prop1Name){
                        tempPropText = refInfo.current.prop1Name;
                        if(refInfo.current.prop2Name){
                            tempPropText = tempPropText.toString() + " یا " + refInfo.current.prop2Name.toString();
                        }
                    }
                    manageCartDiv = <div className="flex flexCol gap10 manageCartDiv">
                        {tempPropText?<p className="faFont textSize5 textClr3">کالا در {tempPropText} دیگر موجود است.</p>:""}
                        <p className="faFont textSize3 notProductExist flex flexCenter">کالا موجود نیست</p>
                    </div>;
                }
            }else{
                if(stateInfo.activeItem.number > 0){
                    manageCartDiv =
                        <div className="flex flexCol manageCartDiv">
                            <button className="addToCartBtn faFont textSize3 flex flexCenter flexCol" onClick={()=>addToCartBtnHandler()} ><span>افزودن به سبد</span></button>
                        </div>;
                }else{
                    if(data.totalNumber > 0){
                        let tempPropText = refInfo.current.prop1Name;
                        if(refInfo.current.prop2Name){
                            tempPropText = tempPropText + " یا " + refInfo.current.prop2Name;
                        }
                        manageCartDiv = <div className="flex flexCol gap10 manageCartDiv">
                            <p className="faFont textSize5 textClr3">کالا در {tempPropText} دیگر موجود است.</p>
                             <p className="faFont textSize3 notProductExist flex flexCenter">کالا موجود نیست</p>
                        </div>;
                    }else{
                        manageCartDiv = <p className="faFont textSize3 notProductExist flex flexCenter">کالا موجود نیست</p>;
                    }
                }
            }
            /////////
            setStateInfo(stateInfo=>({...stateInfo,"stokeElem":prop1LabelElem ?
            <div className="flex flexCol gap10 stockDiv">
                <hr className="mar15"/>
                {prop1LabelElem}
                <div className="flex propList gap5">
                    {prop1ListElem}
                </div>
                {prop2Label}
                <div className="flex propList gap5">
                    {prop2ListElem}
                </div>
                
            </div>
            :""
            ,"manageCartDiv":<div className="productPriceDiv">
                    {manageCartDiv}
                    {priceElem}
                    {pricePropInfoElem}
                </div>}));
        }
    },[stateInfo.activeItem,savedCart]);

    useEffect(()=>{
        if(data.name){
            document.title = "مشخصات و قیمت محصول " + data.name;
        }
        if(data.stocks){
            refInfo.current.stocksData = JSON.parse(data.stocks);
            if(data.hasProp1){
                refInfo.current.prop1Name = data.prop1;
                refInfo.current.prop1 = refInfo.current.stocksData[0].prop1;
                if(data.hasProp2){
                    refInfo.current.prop2Name = data.prop2;
                    refInfo.current.prop1 = refInfo.current.stocksData[0].prop2;
                }
            }
            setStateInfo(stateInfo=>({...stateInfo,"activeItem":refInfo.current.stocksData[0]}));
        }
        mobileImgsList.current = document.querySelector(".mobileImgsList");
    },[data]);
    if(isLoading){
        return <Loading />
    }
    if(error){
        if(error.code === 404)
        {
            navigate("/404");
        }
        return <p>{error.msg}</p>
    }
    
    function fetchComments(){
        const data = new FormData();
        data.append("id",id);
        data.append("counter",refInfo.current.commentCounter);
        fetch("/api2/productComment/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
                
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            if(JSON.parse(result.cmList).length === 0 && refInfo.current.commentCounter === 0){
                setStateInfo(stateInfo=>({...stateInfo,
                commentsListElem:<div className="grid rtl noCommentSection">
                    <div className="flex flexCol noCommentHere">
                        <p className="faFont textSize3 textClr3 infoComment">نظری برای کالا ثبت نشده است.</p>
                        <p className="faFont textSize5 textClr5">اولین نفری باشید که نظر خود را به اشتراک می گذارد.</p>
                    </div>
                </div>
                }));
                
            }else{
                let tempCmList = [...refInfo.current.commentList];
                JSON.parse(result.cmList).map((item,index)=>{
                    tempCmList.push(
                        <div className="flex flexCol commentListItem gap10 rtl" key={refInfo.current.commentCounter * 10 + index}>
                            <div className="flex justifyBetween cmHeader alignCenter">
                                <div className="flex alignCenter gap15 rtl"><p className="faFont textSize4 textClr3 flex flexCenter userAvatar" style={{backgroundColor:generateRndClr()}}>{item.cm.slice(0,1)}</p> <p className="faFont textSize4 textClr3 cmUserName">{item.username}</p> </div>
                                <p className="date faFont textSize6 textClr5">{timeInShamsi(item.date)}</p>
                            </div>
                            <hr className="mar10"/>
                            <p className="faFont textSize4 textClr3 cmText" dir="auto">{toPer(item.cm.toLocaleString())}</p>
                            {item.answer ? <div className="grid answerDiv rtl">
                                <span className="answerLogo hideText">reply</span>
                                <span className="verticalSeperator hideText">line</span>
                                <div className="flex flexCol gap5">
                                    <p className="faFont textSize6 textClr5">پاسخ مدیر سایت :</p>
                                    <p className="faFont textSize4 textClr3">{toPer(item.answer.toLocaleString())}</p>
                                </div>
                            </div> : ""
                            }
                        </div>
                    );
                });
                refInfo.current.commentList = [...tempCmList];
                let loadMore = "";
                let loadingElem = "";
                if(result.hasMoreMsg){
                    loadMore = <button className="faFont textSize5 loadMoreBtn" onClick={()=>{loadMoreClickHandler()}}>نظرات بیشتر</button>
                    loadingElem = <div className="flex flexCol flexCenter loadingElem"><span className="loadingSpan hideText">loading</span></div>
                }
                
                setStateInfo(stateInfo=>({...stateInfo,
                    commentsListElem: 
                    <div className="flex flexCol ">
                        <div className="flex flexCol gap10 mar20">{refInfo.current.commentList}</div>
                        {loadMore}
                        {loadingElem}
                    </div>
                }));
                if(refInfo.current.commentCounter > 0){
                    document.querySelector(".loadMoreBtn").style.display = "flex";
                    document.querySelector(".loadingElem").style.display = "none";
                }
            }
        })
        .catch(err=>{
            showMsg(err.message);
        });
    }
    async function share() {
        const text = document.title;
        const shareData = {
          title: "این مطلب را با دیگران به اشتراک بگذارید.",
          text: text,
          url: window.location.href,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {
          showMsg("لینک کپی شد.");
        }
      }
    function submitCommentClickHandler () {
        let comment = document.querySelector('.insertCommentTextArea').value;
        if(comment.trim() === ""){
            showMsg("لطفا ابتدا نظر خود را وارد کنید.");
            return;
        }
        document.querySelector(".insertCmLoader").style.display = "flex";
        document.querySelector(".submitComment").style.display = "none";
        
        const data = new FormData();
        data.append("usn", userInfo.usn.replaceAll("//","@"));
        data.append("psw", userInfo.psw);
        data.append("cm",comment);
        data.append("productId",id);
        fetch("/api2/insertComment/", {
            method: "POST",
            body: data,
        })
        .then(res => {
            document.querySelector(".insertCmLoader").style.display = "none";
            document.querySelector(".submitComment").style.display = "unset";
            if (res.status === 200) {
                return res.json();
            } else {
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result => {
            if (result.error) {
                let err = new Error(result.msg);
                err.code = result.code;
                throw err;
            }
            showMsg(result.msg);
            document.querySelector('.insertCommentTextArea').value = "";
        })
        .catch(err => {
            showMsg(err.message);
            if(err.code === 412){
                setUserInfo(null);
                localStorage.removeItem("usn");
                localStorage.removeItem("psw");
            }
        });
    }
    function loadMoreClickHandler (){
        refInfo.current.commentCounter++;
        document.querySelector(".loadMoreBtn").style.display = "none";
        document.querySelector(".loadingElem").style.display = "flex";
        fetchComments();
    }
    function addToCartBtnHandler (){
        if(!savedCart[id + "-" + stateInfo.activeItem.id]){
            getProductInfo(id,  stateInfo.activeItem.id).then((res) => {
            if (res) {
                if(res.number > 0){
                setSaveCart(savedCart=>({
                    ...savedCart,
                    [id + "-" + stateInfo.activeItem.id]:{id: id, subId: stateInfo.activeItem.id, number: 1, price: +stateInfo.activeItem.price, discount: +stateInfo.activeItem.discount}
                }));
                setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
                }else{
                setStateInfo(stateInfo=>({...stateInfo,"activeItem":{...stateInfo.activeItem,"number":0}}))
                setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
                }
            } else {
                showMsg(res);
            }
            });
        }
    }
    
    function prop1ChangeHandler(prop1,prop2){
        refInfo.current.prop1 = prop1;
        refInfo.current.prop2 = prop2;
        activeItemChangeHandler();
    }
    function prop2ChangeHandler(prop2){
        refInfo.current.prop2 = prop2;
        activeItemChangeHandler();
    }
    function activeItemChangeHandler(){
        for (let index = 0; index < refInfo.current.stocksData.length; index++) {
            const listItem = refInfo.current.stocksData[index];
            if(!refInfo.current.prop2Name)
            {
                if(listItem.prop1 === refInfo.current.prop1){
                    setStateInfo(stateInfo=>({...stateInfo,"activeItem":listItem}))
                    break;
                }
            }else{
                if(listItem.prop1 === refInfo.current.prop1 && listItem.prop2 === refInfo.current.prop2){
                    setStateInfo(stateInfo=>({...stateInfo,"activeItem":listItem}));
                    break;
                }
            }
        }
    }
    // mobileSwipe
    function touchStart(e){
        isDown = true;
        startX = e.changedTouches[0].pageX - mobileImgsList.current.offsetLeft;
        scrollLeft = mobileImgsList.current.scrollLeft;
    }
    function touchMove(e){
        if(isDown){
            let endX = e.changedTouches[0].pageX - mobileImgsList.current.offsetLeft;
            mobileImgsList.current.scrollLeft = scrollLeft - (endX - startX) ;
        }
    }
    function touchEnd(e){
        if(isDown){
            checkSwipValue(e.changedTouches[0].pageX - mobileImgsList.current.offsetLeft)
        }
        isDown = false;
    }
    //desktopSwipe
    function startMouseClick(e) {
        e.preventDefault();
        isDown = true;
        startX = e.pageX - mobileImgsList.current.offsetLeft;
        scrollLeft = mobileImgsList.current.scrollLeft;
    }
    function startMouseMove(e) {
        if(isDown){
            let endX = e.pageX - mobileImgsList.current.offsetLeft;
            mobileImgsList.current.scrollLeft = scrollLeft - (endX - startX) ;
        }
    }
    function endMouseClick(e){
        if(isDown){
            checkSwipValue(e.pageX - mobileImgsList.current.offsetLeft)
        }
        isDown = false;
    }
    function setSlide(){
        mobileImgsList.current.scrollLeft = coverIndex * mobileImgsList.current.offsetWidth;
        if(coverIndex === 0){
            document.querySelector(".preBtn").style.opacity = ".2";
        }else if(coverIndex === 1){
            document.querySelector(".preBtn").style.opacity = ".9";
        }
        if(coverIndex === JSON.parse(data.imgs).length - 1){
            document.querySelector(".nextBtn").style.opacity = ".2";
        }else if(coverIndex === JSON.parse(data.imgs).length - 2){
            document.querySelector(".nextBtn").style.opacity = ".9";
        }
        setTimeout(() => {
            mobileImgsList.current.style.scrollBehavior = "auto";
        }, 200);
    }
    function checkSwipValue(endX){
        if(startX  > endX + 35  && startX  > endX && coverIndex + 1 < JSON.parse(data.imgs).length){
            coverIndex = coverIndex + 1;
        }else if(startX < endX - 35 && startX < endX && coverIndex > 0){
            coverIndex = coverIndex - 1;
        }
        mobileImgsList.current.style.scrollBehavior = "smooth";
        setSlide();
    }
    function slideBtnHandler(index){
        if(index > 0 && coverIndex + 1 < JSON.parse(data.imgs).length){
            coverIndex = coverIndex + 1;
        }else if(index < 0 && coverIndex > 0){
            coverIndex = coverIndex - 1;
        }
        mobileImgsList.current.style.scrollBehavior = "smooth";
        setSlide();
    }
    function changeMainImg(url,element){
        document.querySelector(".activeMinImgItem").classList.remove("activeMinImgItem");
        element.classList.add("activeMinImgItem");
        document.querySelector(".mainImage").src = url;
    }
    return <section className="container flex flexCol">
        <div className="grid linkDiv rtl">
            <Links data = {[{link:"/",catName:"خانه"} , {link:"/category/?q=cat&catName=" + data.mainCat , catName : data.mainCat} , { link : "/category/?q=cat&catName=" + data.mainCat + "&subCat=" + data.subCat , catName : data.subCat} , {link : "/product/"+data.id+"/"+shortText(data.name,20) , catName : shortText(data.name,20) }]} defClass="direction" />
            <button className="shareBtn btn hideText" onClick={()=>share()}>Share</button>
        </div>
        <div className="grid rtl infoSection">
            <div className="flex flexCol mar10 imgSection ltr">
                <img draggable="false" src={JSON.parse(data.imgs)[0]} alt={shortText(data.name,20)} className="mainImage fitImage" />
                <div className="flex mobileImgsList hideScrollBar " >
                {
                    JSON.parse(data.imgs).map((item,index)=>{
                        return <img draggable="false" key={index} src={item} alt={shortText(data.name,20)} className="imgListItem fitImage" onMouseDown={(e) => startMouseClick(e)} onMouseMove={(e) => startMouseMove(e)} onTouchStart={(e) => touchStart(e)} onTouchEnd={(e)=>touchEnd(e)} onTouchMove={(e)=>touchMove(e)} onMouseUp={(e)=>{endMouseClick(e)}} onMouseLeave={(e)=>{endMouseClick(e)}} />
                    })
                }
                </div>
                <div className="flex minImgsList hideScrollBar rtl" onMouseDown={(e)=>mouseIsDown(e,"minImgsList")} onMouseMove={(e)=>mouseMove(e,"minImgsList")} onMouseLeave={(e)=>mouseLeave(e,"minImgsList")} onMouseUp={(e)=>mouseUp(e,"minImgsList")}>
                {
                    JSON.parse(data.imgs).map((item,index)=>{
                        return <img draggable="false" key={index} src={item} alt={shortText(data.name,20)} className={index === 0 ? "minImgItem fitImage activeMinImgItem" : "minImgItem fitImage"} onClick={(e)=>changeMainImg(item,e.target)}/>
                    })
                }
                </div>
                <button className="nextBtn hideText" onClick={()=>slideBtnHandler(1)}>next</button>
                <button className="preBtn hideText" onClick={()=>slideBtnHandler(-1)}>previous</button>
            </div>
            
            <div className="infoDiv flex flexCol">
                <h1 className="titrFont textClr3 productName">{data.name}</h1>
                <hr className="mar10"/>
                <h3 className="titrFont textSize2 textClr3 detailsHeader mar20">ویژگی های محصول</h3>
                <div className="flex flexCol gap5 shortDetailsList mar20">
                {
                    JSON.parse(data.details).map((item,index)=>{
                        return <div className="flex shortDetailsItem gap10 alignCenter" key={index}>
                            <p className="faFont textSize4 textClr5 itemName">{toPer(item.name.toLocaleString())}</p>
                            <p className="faFont textSize4 textClr5">:</p>
                            <p className="faFont textSize4 textClr3 itemDetail">{toPer(item.detail.toLocaleString())}</p>
                        </div>
                    })
                }
                </div>
                {stateInfo.stokeElem}
            </div>
            {stateInfo.manageCartDiv}
        </div>
        <hr className="mar20"/>
        <div className="flex flexCol rtl mar10">
            <div className="flex flexCol descriptionDiv gap10">
                <h2 className="titrFont discriptionHeader textSize2 textClr3">درباره محصول</h2>
                <p className="faFont textSize4 textClr5 justifyText descriptionText">{toPer(data.description.toLocaleString())}</p>
            </div>
            <div className="flex flexCol mar15">
                <hr/>
                <h2 className="titrFont textSize2 textClr3 detailsTitle mar15">ویژگی های محصول</h2>
                <div className="flex flexCol mar10 detailsList">
                    {
                        JSON.parse(data.details).map((item,index)=>{
                            return <div className="grid detailsItem" key={index}>
                                <p className="faFont textSize4 textClr5 itemName">{toPer(item.name.toLocaleString())}</p>
                                <span className="seperator"></span>
                                <p className="faFont textSize4 textClr5 itemDetail">{toPer(item.detail.toLocaleString())}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
        <div className="flex flexCol recomendedSection mar15 rtl">
            <hr />
            <div className="flex justifyBetween mar15">
                <h2 className="titrFont textSize2 textClr3 recmendedTitle">کالاهای مرتبط</h2>
                <Link to={"/category/"+data.catName} className="faFont textSize5 seeMore">مشاهده همه</Link>
            </div>
            <div className="flex gap5 hideScrollBar recomendedList" onMouseDown={(e)=>mouseIsDown(e,"recomendedList")} onMouseMove={(e)=>mouseMove(e,"recomendedList")} onMouseLeave={(e)=>mouseLeave(e,"recomendedList")} onMouseUp={(e)=>mouseUp(e,"recomendedList")}>
            {
                JSON.parse(data.recomended).map((item,index)=>{
                    return <ProductItem details = {item} key={index}/>
                })
            }
            </div>
        </div>
        <hr className="mar10"/>
        <h2 className="titrFont textSize2 textClr3 rtl commentTitle">نظرات محصول</h2>
        <section className="grid commentSection rtl">
            {stateInfo.commentsListElem}
            {
                userInfo ? <div className="flex flexCol rtl insertCommentDiv">
                    <textarea name="" id="" cols="30" rows="10" className="faFont textSize4 textClr3 insertCommentTextArea" placeholder="نظر خود را اینجا بنویسید ..."></textarea>
                    <button className="faFont textSize4 submitComment" onClick={()=>submitCommentClickHandler()}>ثبت نظر</button>
                    <div className="insertCmLoader hideText">loading</div>
                </div>:
                <div className="flex flexCenter flexCol signToInsertComment rtl">
                    <p className="faFont textSize3 textClr3 info">برای ثبت نظر ابتدا در سایت ثبت نام کنید.</p>
                    <button className="faFont textSize5 textClr5 goToSign" onClick={()=>{navigate("/sign");}}>برای ثبت نام اینجا کلیک کنید</button>
                </div>
            }
        </section>
    </section>
}
