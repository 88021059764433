import React from "react";
import "../style/productItem.css";
import { Link } from "react-router-dom";
import { shortText, toPer } from "./Methods";
export default function ProductItem(prop){
    const details = prop.details;
    let discount = "";
    let price = "";
    let finalPrice = "";
    let notExist = "";
    
    if(details.number > 0){
        if(+details.discount > 0){
            discount = <p className="faFont textSize6 textClr3 discountText">{toPer(details.discount)}</p>;
            price = <p className="faFont textSize6 textClr7 dashedPrice rtl">{toPer(details.price.toLocaleString())}</p>
            finalPrice = <p className="faFont textSize4 textClr3 finalPrice rtl">{toPer(details.finalPrice.toLocaleString())} تومان</p>
        }else{
            price = <p className="faFont textSize4 textClr3 priceText rtl">{toPer(details.price.toLocaleString())} تومان</p>
        }
    }else{
        notExist = <p className="faFont textSize5 productNotExist rtl">موجود نیست</p>
    }
    return <div className="flex flexCol productItem" >
        <Link draggable="false" className="hideText" to={"/product/"+details.id+"/"+shortText(details.name,20)}>
            <img draggable="false" src={details.img} alt={details.name} className="productItemImage fitImage" loading="lazy"/>
        </Link>
        <p className="faFont textSize4 textClr3">{details.name}</p>
        <div className="priceDiv mar5 ltr">
            {discount}
            {price}
            {finalPrice}
            {notExist}
        </div>
        <Link draggable="false" className="faFont textSize4 seeProduct"  to={"/product/"+details.id+"/"+shortText(details.name,4)}>مشاهده محصول</Link>
    </div>
}
