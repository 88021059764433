import React from "react";
import "../style/modal.css";
export default function Modal(){
    function closeModal(){
        const modalContainer = document.querySelector(".modalContainer");
        if(modalContainer){
            modalContainer.classList.remove("activeFlex");
        }
    }
    return <section className="flexCol modalContainer container rtl">
        <button className="closeMsg hideText" type="button" onClick={()=>{closeModal()}}></button>
        <p className="faFont textSize4 textClr5 msgText"></p>
    </section>
}