import { createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CheckIsLogged from "./Component/CheckIsLogged";
import Header from "./Component/Header";
import Home from "./Component/Home";
import Login from "./Component/Login";
import Modal from "./Component/Modal";
import User from "./Component/User";
import Product from "./Component/Product";
import Sign from "./Component/Sign";
import Search from "./Component/Search";
import Category from "./Component/Category";
import Cart from "./Component/Cart";
import LoadCart from "./Component/LoadCart";
import FetchCart from "./Component/FetchCart";
import Tracking from "./Component/Tracking";
import Faq from "./Component/Faq";
import Footer from "./Component/Footer";
import AllBlogs from "./Component/AllBlogs";
import Blog from "./Component/Blog";
import NoResult from "./Component/NoResult";
import BlogSearch from "./Component/BlogSearch";
import About from "./Component/About";

export const AppContext = createContext();

const App = () => {
  const { userInfo, setUserInfo } = CheckIsLogged();
  const { savedCart, setSaveCart } = LoadCart();
  const { displayCart, setDisplayCart } = FetchCart();
  return (
    <>
      <AppContext.Provider
        value={{
          isLogged: [userInfo, setUserInfo],
          cartData: [savedCart, setSaveCart],
          displayCartContext: [displayCart, setDisplayCart],
        }}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/product/:id/:name" element={<Product />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user" element={<User />} />
            <Route path="/sign" element={<Sign />} />
            <Route path="/search" element={<Search />} />
            <Route path="/category" element={<Category />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/tracking" element={<Tracking />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/about" element={<About />} />
            <Route path="/Blog" element={<AllBlogs />} />
            <Route path="/Blog/:id" element={<Blog />} />
            <Route path="/Blogs" element={<BlogSearch />} />
            <Route path="*" element={<NoResult />} />
          </Routes>
          <Modal />
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
};

export default App;
