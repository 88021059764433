import React, { useContext } from "react";
import { shortText, toPer } from "./Methods";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
export default function SoldOutItem(props){
    const {cartData} = useContext(AppContext);
    const [savedCart, setSaveCart] = cartData;
    const {displayCartContext} = useContext(AppContext);
    const [displayCart , setDisplayCart ] = displayCartContext;

    const itemData = props.data;
    let {name} = itemData;
    if(itemData.prop1){
        name += ` - ${itemData.prop1}`;
        if(itemData.prop2){
            name += ` - ${itemData.prop1}`;
        }
    }
    function removeItemClickHandler(){
        let itemId = `${itemData.id}-${itemData.subId}`;
        if(savedCart[itemId]){
            let tempSaveCart = {...savedCart};
            delete tempSaveCart[itemId];
            setSaveCart({...tempSaveCart});
            setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        }
    }
    return <div className="grid soldOutItem">
        <img src={itemData.img} alt={itemData.name} className="soldOutImg fitImage" />
        <Link to={"/product/"+itemData.id+"/"+ shortText(itemData.name,20)} className="faFont textSize5 textClr5">{toPer(name.toLocaleString())}</Link>
        <button className="removeItem hideText" onClick={()=>{removeItemClickHandler()}}>remove</button>
    </div>;
}