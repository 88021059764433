import { useEffect, useState } from "react";

export default function LoadCart(){
    const [savedCart,setSaveCart] = useState("");
    useEffect(()=>{
        loadData();
        window.addEventListener("storage", loadData);
        return () => {
            window.removeEventListener("storage", loadData);
        };
    },[]);
    
    useEffect(()=>{
        if(savedCart!==""){
            localStorage.setItem("crt",JSON.stringify(savedCart));
        }
    },[savedCart]);
    
    
    function loadData(){
        let storageCart = localStorage.getItem("crt");
        let tempStorage = {};
        if(storageCart){
            try {
                tempStorage = JSON.parse(storageCart);
            } catch (error) {
                tempStorage = {};
            }
        }
        setSaveCart(tempStorage);
    }
    return {savedCart,setSaveCart};
}
