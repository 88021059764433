"use strict";
function isPer(text) {
  text = text.trim();
  if (typeof text !== "string") {
    return false;
  }
  var regExp = /[آ-ی]/;
  if (regExp.test(text)) {
    return true;
  }
  return false;
}
function showMsg(text) {
  document.querySelector(".msgText").innerText = text;
  const modalContainer = document.querySelector(".modalContainer");
  if (!modalContainer.classList.contains("activeFlex")) {
    modalContainer.classList.add("activeFlex");
    setTimeout(() => {
      modalContainer.classList.remove("activeFlex");
    }, 3990);
  }
}
function shortText(text, len) {
  return text.length > len ? text.slice(0, len) + " ..." : text;
}
const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
const englishNumbers = [
  /0/g,
  /1/g,
  /2/g,
  /3/g,
  /4/g,
  /5/g,
  /6/g,
  /7/g,
  /8/g,
  /9/g,
];
function toPer(str) {
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str.replace(englishNumbers[i], persianNumbers[i]);
    }
  }
  return str;
}
function timeInShamsi(date) {
  let mountsName = [
    "فروردین",
    "اردیبهشت",
    "خرداد",
    "تیر",
    "مرداد",
    "شهریور",
    "مهر",
    "آبان",
    "آذر",
    "دی",
    "بهمن",
    "اسفند",
  ];
  let arrDate = date.split(" ")[0].split("-");
  let year = +arrDate[0];
  let mount = +arrDate[1];
  let day = +arrDate[2];
  var g_d_m, jy, jm, jd, gy2, days;
  g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  gy2 = mount > 2 ? year + 1 : year;
  days =
    355666 +
    365 * year +
    ~~((gy2 + 3) / 4) -
    ~~((gy2 + 99) / 100) +
    ~~((gy2 + 399) / 400) +
    day +
    g_d_m[mount - 1];
  jy = -1595 + 33 * ~~(days / 12053);
  days %= 12053;
  jy += 4 * ~~(days / 1461);
  days %= 1461;
  if (days > 365) {
    jy += ~~((days - 1) / 365);
    days = (days - 1) % 365;
  }
  if (days < 186) {
    jm = 1 + ~~(days / 31);
    jd = 1 + (days % 31);
  } else {
    jm = 7 + ~~((days - 186) / 30);
    jd = 1 + ((days - 186) % 30);
  }
  if (jm < 10) {
    jm = `0${jm}`;
  }
  if (jd < 10) {
    jd = `0${jd}`;
  }
  return [toPer(String(jd)) + " " + mountsName[+jm] + " " + toPer(String(jy))];
}
let startX;
let scrollLeft;
let isDown;
function mouseIsDown(e, className) {
  const offersList = document.querySelector("." + className);
  isDown = true;
  startX = e.pageX - offersList.offsetLeft;
  scrollLeft = offersList.scrollLeft;
  offersList.style.cursor = "pointer";
}
function mouseUp(e, className) {
  const offersList = document.querySelector("." + className);
  isDown = false;
  offersList.style.cursor = "default";
}
function mouseLeave(e, className) {
  const offersList = document.querySelector("." + className);
  isDown = false;
  offersList.style.cursor = "default";
}
function mouseMove(e, className) {
  const offersList = document.querySelector("." + className);
  if (isDown) {
    e.preventDefault();
    const x = e.pageX - offersList.offsetLeft;
    offersList.scrollLeft = scrollLeft - (x - startX);
  }
}
function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}
async function getProductInfo(id, subId) {
  if (!id || !subId) {
    showMsg("خطای کالای تعریف نشده.");
    return;
  }
  const data = new FormData();
  data.append("id", id);
  data.append("subId", subId);
  try {
    const res = await fetch("/api2/checkProductItem/", {
      method: "POST",
      body: data,
    });
    if (!res.ok) {
      throw new Error("خطایی رخ داده است.");
    }
    const json = await res.json();
    if (json.error) {
      throw new Error(json.msg);
    }
    return json;
  } catch (err) {
    return err.message;
  }
}
function isValidEmail(email) {
  let regExoCode = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (regExoCode.test(email)) {
    return true;
  }
  return false;
}
function generateRndClr() {
  let clr = "#";
  let allChar = "CDEF";
  for (let index = 0; index < 6; index++) {
    clr += allChar[Math.floor(Math.random() * allChar.length)];
  }
  return clr;
}
async function share(text) {
  const shareData = {
    title: "این مطلب را با دیگران به اشتراک بگذارید.",
    text: text,
    url: window.location.href,
  };
  try {
    await navigator.share(shareData);
  } catch (err) {
    showMsg("لینک کپی شد.");
  }
}
export {
  isPer,
  showMsg,
  shortText,
  toPer,
  mouseIsDown,
  mouseMove,
  mouseUp,
  mouseLeave,
  getProductInfo,
  timeInShamsi,
  isValidEmail,
  scrollToTop,
  generateRndClr,
  share,
};
