import React, { useContext, useState } from "react";
import { getProductInfo, showMsg, toPer } from "./Methods";
import { AppContext } from "../App";
import "../style/manageNumber.css";
export default function ManageNumber(props){
    const {cartData} = useContext(AppContext);
    const [savedCart, setSaveCart ] = cartData;
    const {displayCartContext} = useContext(AppContext);
    const [displayCart, setDisplayCart ] = displayCartContext;
    const [waitResponse,setWaitResponse] = useState(false);
    function increaseClickHandler(itemId){
        setWaitResponse(true);
        let id = itemId.split("-")[0];
        let subId = itemId.split("-")[1];
        getProductInfo(id, subId).then((res) => {
            setWaitResponse(false);
            if (res) {
                if(res.number > savedCart[itemId].number){
                    setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
                    setSaveCart(savedCart=>({...savedCart,[itemId]:{...savedCart[itemId],"number":savedCart[itemId].number + 1}}));
                }else{
                    setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
                    setSaveCart(savedCart=>({...savedCart}));
                }
            } else {
            showMsg(res);
            }
        });
    }
    function decreaseClickHandler(itemId){
        if(savedCart[itemId].number > 1){
            setSaveCart(savedCart=>({...savedCart,[itemId]:{...savedCart[itemId],"number":savedCart[itemId].number - 1}}));
            setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        }else{
            let tempSaveCart = {...savedCart};
            delete tempSaveCart[itemId];
            setSaveCart({...tempSaveCart});
            setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        }
    }
    return <div className="grid manageItemDiv alignCenter rtl">
        {
            !waitResponse?
            <button className={props.number === props.max?"hideText increaseBtn deactiveIncreaseBtn":"hideText increaseBtn"} onClick={()=>increaseClickHandler(props.id+"-"+props.subId)} disabled={props.number === props.max?true:false}>increase</button>
            :
            <div className="manageNumberLoadingBtn flex flexCenter"><span></span></div>
        }
        <p className="faFont textSize4 textClr3 productInCartNumber flex flexCenter">{toPer(props.number.toLocaleString())} عدد</p>
        <button className={props.number === 1 ? "hideText decreaseBtn trashBtn":"hideText decreaseBtn"} onClick={()=>decreaseClickHandler(props.id+"-"+props.subId)}>decrease</button>
    </div>
}