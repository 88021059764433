import React, { memo, useState } from "react";
import '../style/customInput.css';

function CustomInput(props){
   const [inputVisi,setInputVisi] = useState(false);
    return (
        <div className="flex flexCol rtl customInputDiv">
            <label className={"inputLabel faFont textSize5 textClr5 flex alignCenter label" + props.name.toLowerCase() } htmlFor={props.name.toLowerCase()}>{props.label}</label>
            <input className={props.isEnglish?"enFont textSize6 textClr3":"faFont textSize5 textClr3"} name={props.name.toLowerCase()} id={props.name.toLowerCase()} type={inputVisi?"text":props.type} value={props.value} onChange={e=>props.onChange(props.name,e.target.value)} placeholder={props.placeholder} autoComplete={props.complete.toLowerCase()} required readOnly={props.readOnly ? true : false }/>
            <button onClick={()=>{setInputVisi(value=>!value)}} style={props.hasToggleBtn?{display:"flex"}:{display:"none"}} className={inputVisi?"changeInputVisi hideText invisi":"changeInputVisi hideText"} type="button">{inputVisi?"visibility":"visibility_off"}</button>
        </div >
    )
}

export default memo(CustomInput);