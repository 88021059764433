import React from "react";
import "../style/pagination.css";
import { toPer } from "./Methods";

export default function Pagination(props){
    let currentPage = props.page;
    let totalPage = props.total;
    return <div className="flex paginationSection gap10 ltr">
        {currentPage > 1 ? <button className="prePage hideText pageHandlerBtn" onClick={()=>props.changePageClickHandler(currentPage-1)}>previous</button> : "" }
        <div className="numbersDiv flex gap5">
            {currentPage > 1 ? <button className="numberBtn faFont textSize4 textClr3" onClick={()=>props.changePageClickHandler(1)}>۱</button>:""}
            {currentPage - 2 > 1 ? <p className="faFont textSize4 textClr3 flex flexCenter seperatorText">...</p>:""}
            {currentPage - 1 > 1 ? <button className="numberBtn faFont textSize4 textClr3" onClick={()=>props.changePageClickHandler(currentPage-1)}>{toPer((currentPage - 1 ).toLocaleString())}</button>:""}
            <p className="numberBtn faFont textSize4 currentBtn"><span className="flex flexCenter">{toPer((currentPage).toLocaleString())}</span></p>
            {currentPage + 1 < totalPage ? <button className="numberBtn faFont textSize4 textClr3" onClick={()=>props.changePageClickHandler(currentPage + 1)}>{toPer((currentPage + 1 ).toLocaleString())}</button>:""}
            {currentPage + 2 < totalPage ? <p className="faFont textSize4 textClr3 flex flexCenter seperatorText">...</p>:""}
            {currentPage < totalPage ? <button className="numberBtn faFont textSize4 textClr3" onClick={()=>props.changePageClickHandler(totalPage)}>{toPer(totalPage.toLocaleString())}</button>:""}
        </div>
        {currentPage < props.total ? <button className="nextPage hideText pageHandlerBtn" onClick={()=>props.changePageClickHandler(currentPage + 1)}>previous</button> : "" }

    </div>
}