import React , { useEffect, useState, useContext } from "react";
import "../style/login.css";
import CustomInput from "./CustomInput";
import { AppContext } from '../App';
import { isValidEmail, showMsg } from "./Methods";
import { Link, useNavigate } from "react-router-dom";

export default function Sign(){
    const { isLogged } = useContext(AppContext);
    const [userInfo, setUserInfo] = isLogged;
    const navigate = useNavigate();
    const [errorText, setError] = useState(null);
    const [loginInfo, setLoginInfo] = useState({
        username: "",
        password: "",
        name: ""
    });
    useEffect(()=>{
        document.title = "ایجاد حساب کاربری";
    },[])
    useEffect(()=>{
        if (userInfo && userInfo.usn && userInfo.psw) {
            navigate("/user");
        }
    },[userInfo]);
    function setUserInfoHandler(prop, value){
        setLoginInfo({ ...loginInfo, [prop]: value });
    }
    function confrimBtnHandler(e){
        e.preventDefault();
        if (loginInfo.password === "" || loginInfo.username === "") {
            setError("لطفا ابتدا تمام کادرها را کامل کنید.");
            showMsg("لطفا ابتدا تمام کادرها را کامل کنید.");
            return;
        }
        if (!isValidEmail(loginInfo.username)) {
            setError("ایمیل نامعتبر است.");
            showMsg("ایمیل نامعتبر است.");
            return;
        }
        setError(null);

        const data = new FormData();
        data.append("username", loginInfo.username.toLocaleLowerCase());
        data.append("password", loginInfo.password);
        data.append("name", loginInfo.name);
        fetch("/api2/sign/", {
            method: "POST",
            body: data,
        })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result => {
            if (result.error) {
                throw new Error(result.msg)
            }
            showMsg(result.msg);
            localStorage.setItem("usn",result.usn.replaceAll("@","//"));
            localStorage.setItem("psw",result.psw);
            setUserInfo({usn:result.usn.replaceAll("@","//"),psw:result.psw});
            navigate("/");
        })
        .catch(err => {
            let msg = err.message === "Failed to fetch" ? "عدم دسترسی به سرور" : err.message;
            showMsg(msg);
            setError(msg);
        });
    }
    return <section className="flex container rtl flexCol formSection gap20">
        <h1 className="titrFont loginTitle textSize1 textClr3 ">ایجاد حساب کاربری</h1>
        <form className="flex flexCol gap10 signForm">
            <CustomInput name="name" complete={"name"} type={"email"} value={loginInfo.name} placeholder={"رها ایرانی"} onChange={setUserInfoHandler} label={"نام"} hasToggleBtn={false} icon="person" isEnglish={false}/>
            <CustomInput name="username" complete={"username"} type={"email"} value={loginInfo.username} placeholder={"example@gmail.com"} onChange={setUserInfoHandler} label={"ایمیل"} hasToggleBtn={false} icon="person" isEnglish={true}/>
            <CustomInput name="password" complete={"current-password"} type={"password"} value={loginInfo.password} placeholder={"****"} onChange={setUserInfoHandler} label={"کلمه عبور"} hasToggleBtn={true} icon="lock" isEnglish={true}/>
            <button className="submitBtn faFont textSize3" onClick={(e) => confrimBtnHandler(e)}>تایید</button>
        </form>
        <p className={errorText ? "faFont textSize4 errorText rtl" : "hide"} >{errorText ? errorText : ""}</p>
        <Link to="/login" className="faFont textSize4 textClr3 link fitContent">برای ورود به حساب خود اینجا کلیک کنید.</Link>
    </section>
}