import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "./Loading";
import { scrollToTop, shortText, toPer } from "./Methods";
import Pagination from "./Pagintaion";
import "../style/search.css";

export default function BlogSearch(){
    const [searchParams] = useSearchParams();
    let q = searchParams.get("q");
    let sort = searchParams.get("sort") ? searchParams.get("sort") : "def";
    const page = useRef(searchParams.get("page") ? searchParams.get("page") : 1);
    const [isLoading , setIsLoading] = useState(true);
    const [data ,setData] = useState("");
    const [tempSort,setTempSort] = useState(sort);
    const [isFilterMenuActive , setIsFilterMenuActive] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        scrollToTop();
        document.title = "جستجو در وبلاگ : "+q;
        fetchData();
    },[searchParams]);

    function fetchData(){
        const formData = new FormData();
        if(q){
            formData.append("q",q);
        }
        if(sort){
            formData.append("sort",sort);
        }
        if(page.current){
            formData.append("page",page.current);
        }
        fetch("/api2/searchBlog/", {
            method: "POST",
            body: formData,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            setIsLoading(false);
            setData({
                result : JSON.parse(result.data),
                totalPage : result.totalPage 
            });
        })
        .catch(err=>{
            console.log(err.message);
            setIsLoading(false);
        });
    }
    function changeParam(){
        let params = [];
        if(q){
            params.push("q=" + q);
        }
        if(sort !== "def"){
            params.push("sort=" + sort);
        }
        if(page.current > 1){
            params.push("page=" + page.current);
        }
        let link = `/blogs`
        if(params.length > 0){
            link = `/blogs/?`+ params.join("&");
        }
        navigate(link);
    }
    function changePageClickHandler(newPage){
        page.current = newPage;
        changeParam();
    }
    return <section className="grid container searchSection blogSearchSection rtl">
        <div className="filterSection grid mar10">
            <div className={isFilterMenuActive ? "filterCloser active" : "filterCloser"} onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); setTempSort(sort)}}></div>
            <div className="searchControllDiv flex">
                <button className="faFont textSize4 textClr5 showFilterMenuBtn" onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); setTempSort(sort)}}>فیلتر نتایج</button>
            </div>
            <div className={isFilterMenuActive ? "filterMenu grid active" : "filterMenu grid"}>
                <div className="flex justifyBetween filterMenuHeaderDiv">
                    <h2 className="titrFont textSize3 textClr3">فیلتر نتایج</h2>
                    <button className="filterCloserBtn hideText btn" onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); setTempSort(sort)}}>close</button>
                </div>
                <div className="filterList flex flexCol customScrollBar">
                    <h2 className="titrFont textSize3 textClr3">مرتب سازی بر اساس :</h2>
                    <div className="flex flexCol filterOptionDiv">
                        <button className={tempSort === "def" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("def")}>جدیدترین</button>
                        <button className={tempSort === "visit" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("visit")}>بیشترین بازدید</button>
                        <button className={tempSort === "cm" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("cm")}>بیشترین نظر</button>
                    </div>
                </div>
                <button className="hideText faFont textSize4 confirmFilter"  onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); sort = tempSort; page.current = 1; changeParam();}}>اعمال فیلتر</button>
            </div>
        </div>
        {
            isLoading ? 
                <Loading />
                :
                data.result && data.result.length > 0 ?
                    <div className="flex flexCol blogSearchLeftSideDiv">
                        <div className="grid blogGridList mar15 gap10">
                            {
                                data.result.map((item,index)=>{
                                    return <div className="flex flexCol blogGridItem gap10" key={index}>
                                        <div className="gridInfoDiv grid">
                                            <img src={item.img} alt={item.name} className="blogGridImg fitImage" />
                                            <div className="flex flexCol gap10">
                                                <Link to={`/blog/${item.link}`} className="titrFont textSize3 textClr3">{ toPer(shortText(item.name.toLocaleString(),100))}</Link>
                                                <p className="faFont textSize6 textClr5 justifyText">{toPer(shortText(item.body.toLocaleString(),150))}</p>
                                            </div>
                                        </div>
                                        <div className="flex justifyBetween mar15 blogItemActionDiv">
                                            <div className="blogPropertyDiv flex alignCenter gap5">
                                                <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(item.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                                <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(item.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                                <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(item.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                                            </div>
                                            <Link to={`/blog/${item.link}`} className="faFont textSize6 seeMore">مشاهده</Link>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {data.totalPage > 1 ? 
                            <Pagination total={data.totalPage} page={page.current} changePageClickHandler={changePageClickHandler}/>
                        : ""}
                            </div>
                    :
                    <p className="notFound">Not Found</p>
        }
    </section>
}