import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "./Loading";
import { scrollToTop, share, shortText, showMsg, timeInShamsi, toPer } from "./Methods";
import "../style/blog.css";
import { AppContext } from "../App";

export default function Blog(){
    const {id} = useParams();
    const [isLoading , setIsLoading ] = useState(true);
    const [data , setData ] = useState("");
    const {isLogged} = useContext(AppContext);
    const [userInfo, setUserInfo ] = isLogged; 
    const navigate = useNavigate();
    const [commentData , setCommentData ] = useState({
        commentList : "",
        commentValue : "",
        submitingComment : false,
        commentPage : 2,
        hasMore : false ,
        loadingComment : false
    });
    const [changingLike , setChangingLike] = useState(false);
    
    useEffect(()=>{
        scrollToTop();
        setIsLoading(true);
        setCommentData({
            commentList : "",
            commentValue : "",
            submitingComment : false,
            commentPage : 2,
            hasMore : false,
            loadingComment : false
        });
        setData("");
        fetchData();
    },[id]);
    function fetchData(){
        const data = new FormData();
        data.append("id",id);
        if(userInfo){
            data.append("usn", userInfo.usn.replaceAll("//","@"));
            data.append("psw", userInfo.psw);
        }
        fetch("/api2/blog/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            setData({
                "content" : JSON.parse(result.content),
                "date" : result.date,
                "likes" : result.likes,
                "cm" : result.cm,
                "title" : result.title,
                "visit" : result.visit,
                "cover" : result.cover,
                'tags' : JSON.parse(result.tags),
                "latest" : JSON.parse(result.latest),
                "relative" : JSON.parse(result.relative),
                "isLiked" : result.isLiked
            });
            setCommentData(commentData=>({
                ...commentData,
                "commentList" : JSON.parse(result.comment),
                "hasMore" : result.hasMore,
            }))
            setIsLoading(false);
        })
        .catch(err=>{
            setIsLoading(false);
            setData("");
            showMsg(err.message);
        });
    }
    function tryAgain(){
        setIsLoading(true);
        setData("");
        fetchData();
    }
    function submitCommentClickHandler () {
        if(commentData.commentValue.trim() === ""){
            showMsg("لطفا ابتدا نظر خود را وارد کنید.");
            return;
        }
        if(userInfo){
            setCommentData(commentData=>({...commentData,submitingComment : true}));
            const data = new FormData();
            data.append("usn", userInfo.usn.replaceAll("//","@"));
            data.append("psw", userInfo.psw);
            data.append("cm",commentData.commentValue);
            data.append("postId",id);
            fetch("/api2/insertBlogComment/", {
                method: "POST",
                body: data,
            })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw Error("عدم دسترسی به سرور");
                }
            })
            .then(result => {
                if (result.error) {
                    let err = new Error(result.msg);
                    err.code = result.code;
                    throw err;
                }
                showMsg(result.msg);
                setCommentData(commentData=>({...commentData,submitingComment : false , commentValue : ""}));
            })
            .catch(err => {
                setCommentData(commentData=>({...commentData,submitingComment : false}));
                showMsg(err.message);
                if(err.code === 412){
                    setUserInfo(null);
                    localStorage.removeItem("usn");
                    localStorage.removeItem("psw");
                }
            });
        }
    }
    function LoadMoreCmClickHandler(){
        setCommentData(commentData=>({
            ...commentData,
            loadingComment : true
        }));
        const data = new FormData();
        data.append("id",id);
        data.append("counter",commentData.commentPage);
        fetch("/api2/blogComment/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            setCommentData(commentData=>({
                ...commentData,
                loadingComment : false,
                hasMore : result.hasMore,
                commentList : JSON.parse(result.comment),
                commentPage : commentData.commentPage + 1 
            }));
        })
        .catch(err=>{
            setCommentData(commentData=>({
                ...commentData,
                loadingComment : false
            }));
            showMsg(err.message);
        });
    }
    function likeClickHandler(){
        setChangingLike(true);
        const data = new FormData();
        data.append("usn", userInfo.usn.replaceAll("//","@"));
        data.append("psw", userInfo.psw);
        data.append("postId",id);
        fetch("/api2/likeBlog/", {
            method: "POST",
            body: data,
        })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result => {
            if (result.error) {
                let err = new Error(result.msg);
                err.code = result.code;
                throw err;
            }
            setData(data=>({...data,isLiked : result.isLiked , likes : result.likeNumber}));
            setChangingLike(false);
        })
        .catch(err => {
            setChangingLike(false);
            showMsg(err.message);
            if(err.code === 412){
                setUserInfo(null);
                localStorage.removeItem("usn");
                localStorage.removeItem("psw");
            }
        });
    }
    function shareClickHandler(){
        share(data.title);
    }
    if(isLoading){
        return <Loading />
    }
    if(!data){
        return <section className="flex flexCol rtl container">
            <div className="noResultDiv flex flexCol">
                <h2 className="titrFont textSize2 textClr3 noResultTitle">نتیجه ای یافت نشد.</h2>
                <button className="faFont textSize5 textClr5 tryAgainBtn" onClick={tryAgain}>تلاش دوباره</button>
            </div>
        </section>
    }
    return <main className="container flex flexCol rtl blogSection">
        <div className="grid blogContentGrid mar25">
            <div className="flex flexCol">
                <div className="tagsList flex gap10">
                    {
                        data.tags.map((item,index)=>{
                            return <Link to={item} key={index} className="faFont textSize6 textClr5 tagItem">{toPer(item.toLocaleString())}</Link>
                        })
                    }
                </div>
                <div className="blogItemHeader grid gap10">
                    <img src={"./"+data.cover} alt={data.name} className="blogItemCover fitImage" />
                    <div className="blogItemInfoDiv flex flexCol gap10">
                        <h1 className="titrFont textSize1 textClr3 justifyText">{toPer(data.title.toLocaleString())}</h1>
                        <div className="flex justifyBetween">
                            <div className="flex gap5 blogItemPropertyDiv alignCenter" >
                                <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(data.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(data.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(data.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                            </div>
                            <p className="faFont textSize5 textClr3 blogItemDate">{timeInShamsi(data.date)}</p>
                        </div>
                    </div>
                </div>
                <hr className="customHr " />
                <div className="flex flexCol blogContentList gap15 mar15">
                    {
                        data.content.map((item,index)=>{
                            if(item.type === "1"){
                                return <h2 key={index} className="titrFont textSize2 textClr3 blogItemTitle">{toPer(item.content.toLocaleString())}</h2>
                            }
                            if(item.type === "2"){
                                return <p className="faFont textSize4 textClr5 itemBlogParagraph justifyText" key={index}>{ toPer(item.content.toLocaleString()) }</p>
                            }
                            if(item.type === "3"){
                                return <Link to={item.link} className="faFont textSize5 textClr3 blogItemLink" key={index}>{toPer(shortText(item.content,60).toLocaleString())}</Link>
                            }
                            if(item.type === "4"){
                                return <img key={index} src={"./"+item.link} alt={item.alt} className="fitImage blogItemImg mar15" loading="lazy" />
                            }
                            if(item.type === "5"){
                                return <video key={index} controls="controls" preload="auto" width="100%" className="blogItemVideo fitImage">
                                    <source src={"./"+item.link} type="video/mp4"></source>
                                    مرورگر شما از ویدیو پشتیانی نمی کند.
                                </video>
                            }
                        })
                    }
                </div>
                <div className="flex gap10 alignCenter">
                    <button onClick={shareClickHandler} className="blogItemShareBtn faFont textSize5 textClr3">اشتراک گذاری</button>
                    <button onClick={likeClickHandler} className={changingLike ? "blogItemLikeBtn faFont textSize5 textClr3 changingLikeBtn" : data.isLiked ? "blogItemLikeBtn faFont textSize5 textClr3 liked" : "blogItemLikeBtn faFont textSize5 textClr3"}>پسندیدن</button>
                </div>
            </div>
            <div className="flex flexCol blogLeftSideDiv gap10">
                <hr className="customHr linksHr" />
                <div className="flex flexCol gap10">
                    <h2 className="titrFont textSize3 textClr3 mar15">مطالب مرتبط</h2>
                    <div className="flex flexCol latestPostList gap10">
                        {
                            data.relative.map((item,index)=>{
                                return <Link className="grid latestPostItem" key={index} to={"/blog/"+item.link}>
                                    <img src={item.cover} alt={item.title} className="latestLinkImg fitImage" />
                                    <div className="flex flexCol gap10">
                                        <p className="faFont textSize5 textClr5">{toPer(item.title.toLocaleString())}</p>
                                        <p className="faFont latestLinkDate textSize6 textClr7">{timeInShamsi(item.date)}</p>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
                <hr className="customHr" />
                <div className="flex flexCol gap10">
                    <h2 className="titrFont textSize3 textClr3">جدیدترین مطالب</h2>
                    <div className="flex flexCol latestPostList gap10">
                        {
                            data.latest.map((item,index)=>{
                                return <Link className="grid latestPostItem" key={index} to={"/blog/"+item.link}>
                                    <img src={item.cover} alt={item.title} className="latestLinkImg fitImage" />
                                    <div className="flex flexCol gap10">
                                        <p className="faFont textSize5 textClr5">{toPer(item.title.toLocaleString())}</p>
                                        <p className="faFont latestLinkDate textSize6 textClr7">{timeInShamsi(item.date)}</p>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
        <hr className="mar10"/>
        <h2 className="titrFont textSize2 textClr3 rtl commentTitle">نظرات محصول</h2>
        <section className="grid commentSection rtl blogItemCommentSection">
            
            {commentData.commentList && commentData.commentList.length > 0 ? 
                <div className="flex flexCol">
                    <div className="flex flexCol gap10 mar15">
                        {
                            commentData.commentList.map((item,index)=>{
                                return <div className="flex flexCol commentListItem gap10 rtl" key={index}>
                                    <div className="flex justifyBetween cmHeader alignCenter">
                                        <div className="flex alignCenter gap15 rtl"><p className="faFont textSize4 textClr3 flex flexCenter userAvatar" style={{backgroundColor:item.clr}}>{item.comment.slice(0,1)}</p> <p className="faFont textSize4 textClr3 cmUserName">{item.name}</p> </div>
                                        <p className="date faFont textSize6 textClr5">{timeInShamsi(item.date)}</p>
                                    </div>
                                    <hr className="mar10"/>
                                    <p className="faFont textSize4 textClr3 cmText" dir="auto">{toPer(item.comment.toLocaleString())}</p>
                                    {item.answer ? <div className="grid answerDiv rtl">
                                        <span className="answerLogo hideText">reply</span>
                                        <span className="verticalSeperator hideText">line</span>
                                        <div className="flex flexCol gap5">
                                            <p className="faFont textSize6 textClr5">پاسخ مدیر سایت :</p>
                                            <p className="faFont textSize4 textClr3">{toPer(item.answer.toLocaleString())}</p>
                                        </div>
                                    </div> : ""
                                    }
                                </div>
                            })
                        }
                    </div>
                    {
                        commentData.hasMore ?  
                            commentData.loadingComment ? 
                            <div className="flex flexCol flexCenter loadingElem activeFlex"><span className="loadingSpan hideText">loading</span></div> :
                                <button className="faFont textSize6 loadMoreBtn" onClick={()=>{LoadMoreCmClickHandler()}}>نظرات بیشتر</button>
                                
                        : ""
                    }
                </div>
            : <div className="grid rtl noCommentSection">
                <div className="flex flexCol noCommentHere">
                    <p className="faFont textSize3 textClr3 infoComment">نظری برای پست ثبت نشده است.</p>
                    <p className="faFont textSize5 textClr5">اولین نفری باشید که نظر خود را به اشتراک می گذارد.</p>
                </div>
            </div>}
            {
                userInfo ? <div className="flex flexCol rtl insertCommentDiv">
                    <textarea value={commentData.commentValue} onChange={(e)=>setCommentData(commentData=>({...commentData,commentValue : e.target.value}))} cols="30" rows="10" className="faFont textSize4 textClr3 insertCommentTextArea" placeholder="نظر خود را اینجا بنویسید ..."></textarea>
                    {
                        commentData.submitingComment ? <div className="insertCmLoader hideText flex">loading</div> : <button className="faFont textSize4 submitComment" onClick={()=>submitCommentClickHandler()}>ثبت نظر</button>
                    }
                </div>:
                <div className="flex flexCenter flexCol signToInsertComment rtl">
                    <p className="faFont textSize3 textClr3 info">برای ثبت نظر ابتدا در سایت ثبت نام کنید.</p>
                    <button className="faFont textSize5 textClr5 goToSign" onClick={()=>{navigate("/sign");}}>برای ثبت نام اینجا کلیک کنید</button>
                </div>
            }
        </section>
    </main>
}
