import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "./Loading";
import { toPer ,showMsg, scrollToTop} from "./Methods";
import Err from "./Err";
import ProductItem from "./ProductItem";
import "../style/search.css";
import Pagination from "./Pagintaion";

export default function Search(){
    const [searchParams, setSearchParams] = useSearchParams();
    let q = searchParams.get("q");
    let sort = searchParams.get("sort") ? searchParams.get("sort") : "def";
    let justExist = searchParams.get("exist") === "true" ? true : false;
    let price = searchParams.get("price") ? searchParams.get("price") : "def";
    const [data,setData] = useState("");
    const [isLoading,setIsLoading] = useState(true);
    const [error,setError] = useState(null);
    const [isFilterMenuActive , setIsFilterMenuActive] = useState(false);
    const [tempSort,setTempSort] = useState(sort);
    const [tempPrice,setTempPrice] = useState(price);
    const page = useRef(searchParams.get("page") ? searchParams.get("page") : 1);
    const totalPage = useRef(0);
    const navigate = useNavigate();
    useEffect(()=>{
        scrollToTop();
        document.title = `جستجوی محصول : ${q}`
        if(data){
            setData("");
            setError(null);
            setIsLoading(true);
        }
        fetchData();
    },[searchParams]);

    function fetchData(){
        if(!q){
            navigate("/");
        }
        const formData = new FormData();
        formData.append("q",q);
        formData.append("sort",sort);
        formData.append("price",price);
        formData.append("justExist",justExist);
        formData.append("page",page.current);
        fetch("/api2/search/", {
            method: "POST",
            body: formData,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            totalPage.current = result.total;
            if(result.total === 0){
                let err = new Error(`برای جستجوی : " ${q} " ، با فیلتر های تعیین شده نتیجه ای یافت نشد.`);
                err.code = 404;
                throw err;
            }
            setError(null);
            setData(result.data);
            setIsLoading(false);
        })
        .catch(err=>{
            let msg = err.message;
            if(msg.toLowerCase() === "failed to fetch"){
                msg = "عدم دسترسی به سرور";
            }
            if(!err.code){
                showMsg(msg);
            }
            setIsLoading(false);
            setError({msg,code:err.code});
        });
    }
    function tryAgain(){
        setData("");
        setError(null);
        setIsLoading(true);
        fetchData();
    }
    function changeParam(){
        let link = `/search/?q=${q}`;
        if(sort !== "def"){
            link += "&sort=" + sort;
        }
        if(price !== "def"){
            link += "&price=" + price;
        }
        if(justExist){
            link += "&exist=" + justExist;
        }
        if(page.current > 1){
            link += "&page=" + page.current;
        }
        navigate(link);
    }
    function changePageClickHandler(newPage){
        page.current = newPage;
        changeParam();
    }
    
    return <section className="grid container searchSection rtl">
        <div className="filterSection grid mar20">
            <div className={isFilterMenuActive ? "filterCloser active" : "filterCloser"} onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); setTempSort(sort)}}></div>
            <div className="searchControllDiv flex">
                <button className="faFont textSize4 textClr5 showFilterMenuBtn" onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); setTempSort(sort)}}>فیلتر نتایج</button>
                <div className="flex alignCenter gap10 toggleExistBtnDiv">
                    <p className="faFont textSize4 textClr5">کالاهای موجود</p>
                    <div className={justExist ? "toggleView flex active":"toggleView flex"} onClick={()=>{justExist=!justExist; changeParam()}}>
                        <div className="hideText">toggle dot</div>
                    </div>
                </div>
            </div>
            <div className={isFilterMenuActive ? "filterMenu grid active" : "filterMenu grid"}>
                <div className="flex justifyBetween filterMenuHeaderDiv">
                    <h2 className="titrFont textSize3 textClr3">فیلتر نتایج</h2>
                    <button className="filterCloserBtn hideText btn" onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); setTempSort(sort)}}>close</button>
                </div>
                <div className="filterList flex flexCol customScrollBar">
                    <h2 className="titrFont textSize3 textClr3">مرتب سازی بر اساس :</h2>
                    <div className="flex flexCol filterOptionDiv">
                        <button className={tempSort === "def" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("def")}>پیش فرض</button>
                        <button className={tempSort === "mp" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("mp")}>گرانترین</button>
                        <button className={tempSort === "lp" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("lp")}>ارزانترین</button>
                        <button className={tempSort === "pp" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempSort("pp")}>محبوب ترین</button>
                    </div>
                    <h2 className="titrFont textSize3 textClr3 mar15">انتخاب بازه قیمت :</h2>
                    <div className="flex flexCol filterOptionDiv">
                        <button className={tempPrice === "def" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempPrice("def")}>پیش فرض</button>
                        <button className={tempPrice === "1" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempPrice("1")}>کمتر از ۱ میلیون</button>
                        <button className={tempPrice === "2" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempPrice("2")}>بین ۱ تا ۲ میلیون</button>
                        <button className={tempPrice === "3" ? "faFont textSize4 textClr3 active" : "faFont textSize4 textClr3"} onClick={()=>setTempPrice("3")}>بیشتر از ۲ میلیون</button>
                    </div>
                </div>
                <button className="hideText faFont textSize4 confirmFilter"  onClick={()=>{setIsFilterMenuActive(!isFilterMenuActive); sort = tempSort; price = tempPrice; changeParam();}}>اعمال فیلتر</button>
            </div>
        </div>
        {
            isLoading ? <Loading /> :
            error ? <Err error={error.msg} clickHandler={tryAgain} hasTryAgain={error.code}/> :
            <div className="flex flexCol">
                <div className="grid searchGridList">
                    {
                        JSON.parse(data).map((item,index)=>{
                            return <ProductItem details = {item} key={index}/>
                        })
                    }
                </div>
                {totalPage.current > 1 ? 
                    <Pagination total={totalPage.current} page={page.current } changePageClickHandler={changePageClickHandler}/>
                : ""}
            </div>
        }
        
    </section>
}