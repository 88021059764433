import React from "react";
import { shortText, toPer } from "./Methods";
import ManageNumber from "./ManageNumber";
import { Link } from "react-router-dom";
export default function CartItem(props){
    let itemData = props.data;
    return <div className="flex flexCol cartListItem">
        <div className="grid cartTopDiv rtl">
            <img src={itemData.img} alt={itemData.name} className="fitImage cartItemImage" />
            <div className="flex flexCol gap5">
                <Link draggable={false} to={"/product/"+itemData.id+"/"+shortText(itemData.name,20)} className="faFont textSize2 textClr3">{toPer(itemData.name.toLocaleString())}</Link>
                <div className="flex gap5 alignCenter mar5">
                    {itemData.prop1!==""?<p className="faFont textSize5 textClr5">{toPer(itemData.prop1.toLocaleString())}</p>:""}
                    {itemData.prop2!==""?<p className="faFont textSize5 textClr5">-</p>:""}
                    {itemData.prop2!==""?<p className="faFont textSize5 textClr5">{toPer(itemData.prop2.toLocaleString())}</p>:""}
                </div>
                <hr className="customHr" />
                <div className="flex flexCol cartItemPriceDiv">
                    {itemData.discountPercent > 0 ? <div className="flex flexCol">
                        <p className="faFont textSize5 textClr3 discountPercent">{toPer(itemData.discountPercent.toLocaleString())}</p>
                        <p className="faFont textSize5 textClr5 realPrice flex justifyBetween"><span>تخفیف</span> <span>{toPer(itemData.price.toLocaleString())} تومان</span></p>
                    </div>:""}
                    {itemData.discountPercent > 0 ? 
                    <p className="faFont textSize4 textClr3 offPrice flex justifyBetween mar5"><span className='textSize4 textClr5'>قیمت نهایی</span>{toPer(itemData.discountPrice.toLocaleString())} تومان</p>
                    :
                    <p className="faFont textSize4 textClr3 flex justifyBetween"><span className='textSize4 textClr5'>قیمت نهایی</span>{toPer(itemData.price.toLocaleString())} تومان</p>
                }
                </div>
                <div className="flex flexCol manageNumberSection ltr">
                    <ManageNumber number={itemData.number} max={itemData.max} id={itemData.id} subId = {itemData.subId} />
                </div>
            </div>
        </div>
        {itemData.isNumberChanged?<p className="faFont textSize6 textClr3 mar5 numberChangedText">موجودی این کالا تغییر کرده است</p>:""}
        {itemData.isPriceChanged?<p className={itemData.discountPrice - itemData.saveItemPrice > 0?"faFont textSize6 mar5 textClr3 priceChangedText redText":"faFont textSize6 mar5 textClr3 priceChangedText greenText"}>قیمت این کالا {itemData.discountPrice - itemData.saveItemPrice > 0 ? toPer((Math.abs(itemData.discountPrice - itemData.saveItemPrice)).toLocaleString())+" تومان زیاد شده است.":toPer((Math.abs(itemData.discountPrice - itemData.saveItemPrice)).toLocaleString())+" تومان کم شده است."}</p>:"" }
    </div>
}