import React from "react";
import "../style/footer.css";
import { scrollToTop } from "./Methods";
import { Link } from "react-router-dom";
export default function Footer(){
    
    return <footer className="rtl flex flexCol container mar20">
        <button className="goToTopBtn faFont textSize4 mar15 textClr3" onClick={scrollToTop} >ابتدای صفحه</button>
        <section className="footerContent grid gap20">
            <div className="flex flexCol gap10">
                <h2 className="titrFont textSize1 textClr3">فروشگاه برن شاپ</h2>
                <p className="faFont textSize4 justifyText textClr5">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                </p>
            </div>
            <hr className="seperator"/>
            <div className="flex flexCol gap10">
                <h2 className="titrFont textSize1 textClr3">راهنمای مشتریان</h2>
                <div className="grid customersGuid gap5">
                    <Link to="/tracking" className="faFont textSize4 textClr5 linkItem">پیگیری سفارش</Link>
                    <Link to="/faq" className="faFont textSize4 textClr5 linkItem">پرسش های متدوال</Link>
                    <Link to="/about" className="faFont textSize4 textClr5 linkItem">درباره ما</Link>
                    <Link to="/category/?q=offers" className="faFont textSize4 textClr5 linkItem">پیشنهادات شگفت انگیز</Link>
                    <Link to="/blog" className="faFont textSize4 textClr5 linkItem">وبلاگ</Link>
                </div>
            </div>
            <hr className="seperator"/>
            <div className="flex flexCol">
                <h2 className="titrFont textSize1 textClr3">تماس با ما</h2>
                <div className="flex flexCol mar10">
                    <p className="faFont textSize4 textClr5 address callLink"><a className="textClr5" target="_blank" href="https://goo.gl/maps/pQPHRZGqf26wtCdE8">مشهد - وکیل آباد</a></p>
                    <p className="faFont textSize4 textClr5 phone callLink"><a href="tel:09158771678" className="textClr5">۰۹۱۵۸۷۷۱۶۷۸</a></p>
                    <p className="faFont textSize4 textClr5 email callLink"><a href="mailto:addevs14@gmail.com" className="textClr5">addevs14@gmail.com</a></p>
                </div>
                <div className="flex mar15 flexCenter gap5">
                    <a href="https://www.instagram.com" className="socialLink hideText insta">instagram</a>
                    <a href="https://www.telegram.me" className="socialLink hideText telegram">telegram</a>
                    <a href="https://wa.me/09158771678" className="socialLink hideText whatsapp">telegram</a>
                </div>
            </div>
        </section>
    </footer>
}