import {  useEffect, useState } from "react";
import { showMsg } from "./Methods";

export default function FetchCart(){
    const [displayCart,setDisplayCart] = useState("");
    useEffect(()=>{
        loadData();
        window.addEventListener("storage", loadData);
        return () => {
            window.removeEventListener("storage", loadData);
        };
    },[]);
    useEffect(()=>{
        if(displayCart.dataChanged){
            loadData();
        }
    },[displayCart]);

    
    function loadData(){
        let storageCart = localStorage.getItem("crt");
        let tempStorage = {};
        if(storageCart){
            try {
                tempStorage = JSON.parse(storageCart);
            } catch (error) {
                tempStorage = {};
            }
        }
        
        let tempIdList = [];
        Object.entries(tempStorage).map(item=>{
            let productId = item[0].split("-");
            if(productId.length === 2){
                tempIdList.push({id:productId[0],subId:productId[1]});
            }
        });
        if(tempIdList.length > 0){
            fetchCartData(tempIdList,tempStorage);
        }else{
            setDisplayCart({number:0,list:[],soldOut:[],totalPrice:0,finalPrice:0,tcPrice:0,err:false,dataChanged:false});
        }
    }
    
    
    function fetchCartData(idList,tempStorage){
        const data = new FormData();
        data.append("list",JSON.stringify(idList));
        fetch("/api2/checkCart/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg);
            }
            checkCart(JSON.parse(result.data),result.tcPrice,tempStorage);
        })
        .catch(err=>{
            setDisplayCart({number:0,list:[],soldOut:[],totalPrice:0,finalPrice:0,tcPrice:0,err:true,dataChanged:false});
            let msg = err.message;
            if(msg.toLowerCase()==="failed to fetch"){
                msg = "عدم ارتباط با سرور";
            }
            showMsg(msg);
        });
    }
    function checkCart(data,tcPrice,savedCart){
        let cartCounter = 0;
        let tempCartList = [];
        let tempSoldOutList = [];
        let tempCartData = {};
        let totalPrice = 0;
        let finalPrice = 0;
        data.map(item=>{
            let isPriceChanged = false;
            let isNumberChanged = false;
            let saveItem = {...savedCart[item["id"] + "-" + item["subId"]]};

            let realPrice = +item.price;
            let discountPercent = +item.discount;
            let discountPrice = realPrice - (discountPercent * realPrice / 100);
            if(saveItem.number > item.number){
                saveItem.number = item.number;
                isNumberChanged = true;
            }
            if(saveItem.discount !== item.discount){
                saveItem.discount = item.discount;
                isPriceChanged = true;
            }
            if(saveItem.price !== item.price){
                saveItem.price = item.price;
                isPriceChanged = true;
            }
            tempCartData[item["id"]+"-"+item["subId"]] = {...saveItem};
            if(saveItem.number > 0){
                totalPrice += realPrice * saveItem.number;
                finalPrice += discountPrice * saveItem.number;
                tempCartList.push({img:item.img,
                    name:item.name,
                    prop1:item.prop1,
                    prop2:item.prop2,
                    price:realPrice,
                    discountPercent:discountPercent,
                    discountPrice:discountPrice,
                    isNumberChanged,
                    isPriceChanged,
                    id:item.id,
                    subId:item.subId,
                    number:saveItem.number,
                    max:item.number,
                });
                cartCounter ++;
            }else{
                tempSoldOutList.push({
                    img:item.img,
                    name:item.name,
                    id:item.id,
                    subId:item.subId,
                    prop1:item.prop1,
                    prop2:item.prop2,
                });
            }
        });
        setDisplayCart(displayCart=>({...displayCart,number:cartCounter,list:tempCartList,soldOut:tempSoldOutList,totalPrice:totalPrice,finalPrice:finalPrice,tcPrice : tcPrice,err:false,dataChanged:false}));
    }
    
    return {displayCart,setDisplayCart};
}
