import React from "react";
import "../style/user.css";
import { useEffect ,useContext ,useState} from "react";
import { mouseIsDown, mouseLeave, mouseMove, mouseUp} from "./Methods";
import { AppContext } from "../App";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "./Loading";
import UserEdit from "./UserEdit";
import UserOrders from "./UserOrders";
import UserPass from "./UserPass";
export default function User(){
    const {isLogged} = useContext(AppContext);
    const [userInfo, setUserInfo ] = isLogged;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const q = searchParams.get("q");
    const [userData,setUserData] = useState({usn:"",psw:""});

    useEffect(()=>{
        document.title = "پروفایل کاربر";
        if (userInfo && userInfo.usn && userInfo.psw) {
            setUserData({
                usn: userInfo.usn.replaceAll("//","@") ,
                psw : userInfo.psw
            });
        }
        else{
            navigate("/login");
        }
    },[]);
    
    
    function logOutClickHandler(){
        localStorage.removeItem("usn");
        localStorage.removeItem("psw");
        setUserInfo(null);
        navigate("/");
    }
    function changeMenu(newMenu){
        if(q !== newMenu){
            navigate("/user/?q="+newMenu);
        }
    }
    
    if(!userData.usn || !userData.psw){
        return <Loading />;
    }
    return <section className="userSection flex flexCol container rtl">
        {
            !q ? <div className="flex flexCol">
                <div className="menuOptionDiv grid">
                    <button className="largeBtn titrFont textSize2 textClr5 orders" onClick={()=>changeMenu("orders")}>سفارش های من</button>
                    <button className="largeBtn titrFont textSize2 textClr5 edit" onClick={()=>changeMenu("edit")}>ویرایش اطلاعات</button>
                    <button className="largeBtn titrFont textSize2 textClr5 changePass" onClick={()=>changeMenu("changePass")}>تغییر کلمه عبور</button>
                    <button className="largeBtn titrFont textSize2 textClr5 logOut" onClick={()=>logOutClickHandler()}>خروج از حساب کاربری</button>
                </div>
            </div> : 
            <div className="grid profileGridContent">
                <div className="flex alignCenter gap20 userActionDiv hideScrollBar rtl mar10" onMouseDown={(e)=>mouseIsDown(e,"userActionDiv")} onMouseMove={(e)=>mouseMove(e,"userActionDiv")} onMouseLeave={(e)=>mouseLeave(e,"userActionDiv")} onMouseUp={(e)=>mouseUp(e,"userActionDiv")}>
                    <button onClick={()=>changeMenu("orders")} className={q==="orders"?"changeMenuBtn faFont textSize4 textClr3 activeMenuBtn":"changeMenuBtn faFont textSize5 textClr7"}>سفارش های من</button>
                    <button onClick={()=>changeMenu("edit")} className={q==="edit"?"changeMenuBtn faFont textSize4 textClr3 activeMenuBtn":"changeMenuBtn faFont textSize5 textClr7"}>ویرایش اطلاعات</button>
                    <button onClick={()=>changeMenu("changePass")} className={q==="changePass"?"changeMenuBtn faFont textSize4 textClr3 activeMenuBtn":"changeMenuBtn faFont textSize5 textClr7"}>تغییر کلمه عبور</button>
                    <button onClick={logOutClickHandler} className={q==="logOut"?"changeMenuBtn faFont textSize4 textClr3 activeMenuBtn":"changeMenuBtn faFont textSize5 textClr7"}>خروج از حساب</button>
                </div>
                {
                    q === "orders" ? <UserOrders usn={userData.usn} psw={userData.psw}/> : ""
                }
                {
                    q === "edit" ? <UserEdit usn={userData.usn} psw={userData.psw}/>
                    :""
                }
                {
                    q==="changePass" ? <UserPass usn={userData.usn} psw={userData.psw}/> : ""
                }
            </div>
        }
        
    </section>;
}