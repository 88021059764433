import React, { useState } from "react";
import CustomInput from "./CustomInput";
import { scrollToTop, showMsg } from "./Methods";
import { useNavigate } from "react-router-dom";
export default function UserPass(props){
    const usn = props.usn;
    const psw = props.psw;
    const [userData , setUserData ] = useState({
        currentPass:"",
        newPass:"",
        submitingData : false
    });
    const navigate = useNavigate();
    
    function setUserInfoHandler(prop, value){
        setUserData({ ...userData, [prop]: value });
    }
    function submitUserPass(){
        if(!userData.currentPass || !userData.newPass){
            showMsg("لطفا ابتدا همه ی کادر ها را پر کنید.");
            return;
        }
        if(userData.currentPass === userData.newPass){
            showMsg("کلمه عبور کنونی و جدید نباید یکسان باشد.");
            return;
        }
        const data = new FormData();
        data.append("usn",usn);
        data.append("psw",psw);
        data.append("currentPass",userData.currentPass);
        data.append("newPass",userData.newPass);
        fetch("/api2/editUserPass/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            setUserData(userData=>({...userData,submitingData:false}));
            showMsg("کلمه عبور با موفقیت به روز شد.");
            scrollToTop();
            navigate("/user");
        })
        .catch(err=>{
            setUserData(userData=>({...userData,submitingData:false}));
            showMsg(err.message)
        });
    }
    return <div className="flex flexCol gap20 editUserPassSection mar20">
        <h2 className="titrFont textSize2 textClr3 ordersTitle">تغییر کلمه عبور</h2>
        <form action="" onSubmit={(e)=>e.preventDefault()} className="flex flexCol gap20">
        <CustomInput name="email" complete={"email"} type={"email"} value={usn} placeholder={"example@gmail.com"} onChange={setUserInfoHandler} label={"ایمیل"} hasToggleBtn={false} icon="email" isEnglish={true} readOnly={true}/>
            <CustomInput name="currentPass" complete={"current-password"} type={"password"} value={userData.currentPass} placeholder={"****"} onChange={setUserInfoHandler} label={"کلمه عبور کنونی"} hasToggleBtn={true} icon="lock" isEnglish={true}/>
            <CustomInput name="newPass" complete={"new-password"} type={"password"} value={userData.newPass} placeholder={"****"} onChange={setUserInfoHandler} label={"کلمه عبور جدید"} hasToggleBtn={true} icon="lock" isEnglish={true}/>
            {
                !userData.submitingData  ?  <button className="submitBtn faFont textSize4" onClick={submitUserPass}>تایید</button>
                : <div className="loadBlueBtn flex flexCenter"><span></span></div>
            }
        </form>
    </div>
}