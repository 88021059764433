import React, { useEffect } from "react";
import { Link } from "react-router-dom";
export default function NoResult(){
    useEffect(()=>{
        document.title = "نتیجه ای یافت نشد.";
    },[]);
    return <section className="flex flexCol container rtl">
        <div className="noResultDiv flex flexCol flexCenter gap10">
            <h2 className="titrFont textSize1 textClr3 noResultTitle">برای درخواست شما نتیجه ای یافت نشد.</h2>
            <Link to="/" className="faFont textSize5 textClr7 noResultLink">بازگشت به صفحه اصلی</Link>
        </div>
    </section>
}