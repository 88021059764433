import React from "react";
import '../style/loading.css';

export default function Loading(){
    return(
        <div className="loadingContainer container">
            <div className="loadingDiv">
                <span className="load load1"></span>
                <span className="load load2"></span>
                <span className="load load3"></span>
            </div>
        </div>
    );
}