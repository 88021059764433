import React, { useContext, useRef } from "react";
import "../style/home.css";
import useFetch from "./useFetch";
import Loading from "./Loading";
import Covers from "./Covers";
import Offers from "./Offers";
import Links from "./Links";
import { useState } from "react";
import { shortText, toPer } from "./Methods";
import ProductItem from "./ProductItem";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { AppContext } from "../App";

export default function Home (){
    const {data,error,isLoading} = useFetch("/api2/main/");
    const [activeMenu ,setActiveMenu] = useState("new");
    const moreLink = useRef("/");
    const {displayCartContext} = useContext(AppContext);
    const [displayCart,setDisplayCart ] = displayCartContext;

    useEffect(()=>{
        document.title = "فروشگاه اینترنتی برن شاپ";
        if(displayCart){
            setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        }
    },[]);
    
    if(isLoading){
        return <Loading />
    }
    if(error){
        return <p>{error}</p>
    }
   
    let covers = "";
    let offers = "";
    let mobileLinks = "";
    let imgsLink = "";
    let newProducts = "";
    let popularProducts = "";
    let displayMenuList = "";
    if(data.covers){
        if(JSON.parse(data.covers).length > 0)
            covers = <Covers covers={JSON.parse(data.covers)} />
    }
    if(data.offers){
        if(JSON.parse(data.offers).length > 0)
            offers = <Offers offers = {JSON.parse(data.offers)} />
    }
    if(data.mobileLinks){
        if(JSON.parse(data.mobileLinks).length > 0){
            mobileLinks = <Links data = {JSON.parse(data.mobileLinks)} defClass="mobileLinks"/>
        }
    }
    if(data.imgsLink){
        if(JSON.parse(data.imgsLink).length > 0){
            imgsLink = <section className="imgLinkSection flex gap10">
            {
                JSON.parse(data.imgsLink).map((item,index)=>{
                    let linkDesc = shortText(item.title,100);
                    return <div className="flex imgLinkItemDiv" key={index}>
                        <img draggable="false" src={item.img} alt={linkDesc} className="imgLinkImg fitImage" loading="lazy" />
                        <a href={item.link} className="linkTarget hideText">{linkDesc}</a>
                    </div>
                })
            }
            </section>
        }
    }
    if(data.newProducts){
        if(JSON.parse(data.newProducts).length > 0){
            newProducts = <section className="gridItemList gap10 rtl grid">
                {
                    JSON.parse(data.newProducts).map((item,index)=>{
                        return <ProductItem details = {item} key={index}/>
                    })
                }
            </section>
        }
    }
    if(data.popularProducts){
        if(JSON.parse(data.popularProducts).length > 0){
            popularProducts = <section className="gridItemList gap10 rtl grid">
                {

                    JSON.parse(data.popularProducts).map((item,index)=>{
                        return <ProductItem details = {item} key={index}/>
                    })
                }
            </section>
        }
    }
    if(activeMenu === "new"){
        displayMenuList = newProducts;
        moreLink.current = "category/?q=new";
    }else{
        displayMenuList = popularProducts;
        moreLink.current = "category/?q=popular";
    }
    return <main className="flex flexCol container" >
        {covers}
        <div className="flex flexCol homeLinks">
            {mobileLinks}
        </div>
        {offers}
        <div className="flex justifyBetween rtl mar20">
            <div className="flex toggleMenuProductDiv alignCenter rtl">
                <button onClick={()=>{if(activeMenu!=="new") setActiveMenu("new")}} className={activeMenu==="new"?"faFont textSize5 textClr5 toggleMenuProductBtn active new":"faFont textSize5 textClr9  toggleMenuProductBtn new"} >جدیدترین ها</button>
                <button onClick={()=>{if(activeMenu!=="popular") setActiveMenu("popular")}} className={activeMenu==="popular"?"faFont textSize5 textClr5 toggleMenuProductBtn active popular":"faFont textSize5 textClr9  toggleMenuProductBtn popular"} >محبوب ترین ها</button>
            </div>
            <Link className="faFont textSize5 textClr3 moreList btn flex alignCenter" to={moreLink.current}>موارد بیشتر</Link>
        </div>
        {displayMenuList}
        {imgsLink}
        {
            data.blogs && JSON.parse(data.blogs).length > 0 ? 
            <div className="flex flexCol mar10 gap10 rtl">
                <hr className="customHr" />
                <div className="flex justifyBetween">
                    <h2 className="titrFont textSize2 textClr3 blogTitle">آخرین مطالب</h2>
                    <Link to="/blogs" className="faFont seeMore textSize5">مشاهده همه</Link>
                </div>
                <div className="grid homeBlogGrid gap10">
                    {
                        JSON.parse(data.blogs).map((item,index)=>{
                            return <div className="flex flexCol blogGridItem gap10" key={index}>
                                <div className="gridInfoDiv grid">
                                    <img src={item.img} alt={item.name} className="blogGridImg fitImage" />
                                    <div className="flex flexCol gap10">
                                        <Link to={`/blog/${item.link}`} className="titrFont textSize3 textClr3">{ toPer(shortText(item.name.toLocaleString(),100))}</Link>
                                        <p className="faFont textSize6 textClr5 justifyText">{toPer(shortText(item.body.toLocaleString(),150))}</p>
                                    </div>
                                </div>
                                <div className="flex justifyBetween mar15 blogItemActionDiv">
                                    <div className="blogPropertyDiv flex alignCenter gap5">
                                        <p className="blogInfoText faFont textSize6 textClr3 visitText">{toPer(item.visit.toLocaleString())}<span className="blogInfoHoverText">بازدید</span></p>
                                        <p className="blogInfoText faFont textSize6 textClr3 likeText">{toPer(item.likes.toLocaleString())}<span className="blogInfoHoverText">لایک ها</span></p>
                                        <p className="blogInfoText faFont textSize6 textClr3 commentText">{toPer(item.cm.toLocaleString())}<span className="blogInfoHoverText">نظرات</span></p>
                                    </div>
                                    <Link to={`/blog/${item.link}`} className="faFont textSize6 seeMore">مشاهده</Link>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            : ""
        }
        
    </main>
}