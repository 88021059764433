import React, { useEffect, useState} from "react";
import "../style/cart.css";
import { useContext } from "react";
import { AppContext } from "../App";
import Loading from "./Loading";
import CartItem from "./CartItem";
import { showMsg, toPer } from "./Methods";
import SoldOutItem from "./SoldOutItem";

export default function InCartMenu(props){
    const {displayCartContext} = useContext(AppContext);
    const [displayCart,setDisplayCart ] = displayCartContext;
    const [tcList , setTcList] = useState("");

    const emptyCartElem = <div className="flex flexCol flexCenter emptyCartDiv mainEmpty">
        <span className="emptyCartImage hideText">empty cart</span>
        <p className="titrFont textSize2 textClr3">سبد خرید شما خالی است.</p>
    </div>;
   
    useEffect(()=>{
        if(displayCart){
            setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
        }
        loadTcFromStorage();
    },[]);
    function loadTcFromStorage(){
        let tcsInStorage = localStorage.getItem("tcs");
        if(tcsInStorage){
            let tempTcObject = {};
            let tempTcList = [];
            try {
                tempTcObject = JSON.parse(tcsInStorage);
            } catch (error) {
                tempTcObject = {};
            }
            Object.entries(tempTcObject).map(item=>{
                tempTcList.push({
                    tc:item[1].code,
                    price:item[1].price,
                });
            });
            if(tempTcList.length > 0){
                checkTcsList(tempTcList);
            }else{
                setTcList([]);
            }
        }else{
            setTcList([]);
        }
    }
    function checkTcsList(list){
        const data = new FormData();
        data.append("data",JSON.stringify(list));
        fetch("/api2/checkTcs/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg);
            }
            updateTcStorage(JSON.parse(result.data));
        })
        .catch(err=>{
            setTcList([]);
            let msg = err.message;
            if(msg.toLowerCase()==="failed to fetch"){
                msg = "عدم ارتباط با سرور";
            }
            showMsg(msg);
        });
    }
    function updateTcStorage(list){
        let newStorageList = {};
        list.map(item=>{
            newStorageList[item.code] = {
                code:item.code,
                price:item.price
            }
        });
        localStorage.setItem("tcs",JSON.stringify(newStorageList));
        setTcList(list);
    }
    function tryAgain(){
        setDisplayCart(displayCart=>({...displayCart,dataChanged:true}));
    }
    function cancelPurchaseClickHandler(code,price){
        const data = new FormData();
        data.append("code",code);
        data.append("price",price);
        fetch("/api2/cancelTc/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg);
            }
            showMsg(result.msg);
            loadTcFromStorage();
        })
        .catch(err=>{
            let msg = err.message;
            if(msg.toLowerCase()==="failed to fetch"){
                msg = "عدم ارتباط با سرور";
            }
            showMsg(msg);
        });
    }
    return <section className="flex flexCol cartSection rtl">
    {
        tcList.length > 0 ? <div className="flex flexCol mar15">
            <h2 className="titrFont textSize3 textClr5">سفارشات در انتظار پرداخت</h2>
            <div className="activeTcList grid mar15">
                {
                    tcList.map((item,index)=>{
                        return <div className="grid activeTcItem gap10" key={index}>
                            <img src={item.img} alt={item.name} className="fitImage tcImage" />
                            <div className="flex flexCol">
                                <p className="faFont textSize5 textClr3">{ toPer(item.name.toLocaleString())}</p>
                                <p className="faFont textSize5 textClr5 price">{toPer(item.price.toLocaleString())} تومان</p>
                            </div>
                            <div className="flex flexCol gap5">
                            <form action="/submitPurchase" method="post">
                                <input type="hidden" name="price" value={item.price}/>
                                <input type="hidden" name="tc" value={item.code}/>
                                <button className="faFont confirmBtn textSize5">پرداخت</button>
                            </form>
                                <button className="cancelItem faFont textSize5" onClick={()=>cancelPurchaseClickHandler(item.code,item.price)} >لغو</button>
                            </div>
                        </div>
                    })
                }
            </div>
        </div> : "" 
    }
    {
        !displayCart || !tcList ? <Loading />
        :
        displayCart.err?<div className="flex flexCol errorCartDiv">
            <p className="errorCartText titrFont textSize2 textClr3 flex flexCenter">خطا در دریافت اطلاعات سبد خرید</p>
            <button className="tryAgain faFont textSize4 textClr5" onClick={tryAgain}>تلاش دوباره</button>
        </div>
        :
        <div className={displayCart.number > 0 ? "cartDetailsGridSection grid gap20" : "flex flexCol"}>
            <div className="flex flexCol rightSideDiv">
                <h1 className="titrFont textSize2 textClr3 mar10 cartTitle flex flexCenter"><span>سبد خرید</span></h1>
                {
                    displayCart.number > 0 ?
                    <div className="flex flexCol gap10">
                    {
                        displayCart.list.map((item,index)=>{
                            return <CartItem data={item} key={index} />
                        })
                    }
                        {
                            displayCart.soldOut.length > 0 ?
                            <div className="flex flexCol gap10 soldOutDiv">
                                <h2 className="titrFont textSize3 soldOutTitle flex flexCenter">آیتم های زیر تمام شده است.</h2>
                                {
                                    displayCart.soldOut.map((item,index)=>{
                                        return <SoldOutItem data={item} key={index} />
                                    })
                                }
                            </div>
                            :""
                        }
                        
                    </div>
                    :
                    <div className={displayCart.soldOut.length > 0 ? "emptyCartListGrid grid" : "flex flexCol"}>
                        {emptyCartElem}
                        {displayCart.soldOut.length > 0 ?
                            <div className="flex flexCol">
                                <h2 className="titrFont textSize3 soldOutTitle flex flexCenter mar10">آیتم های زیر تمام شده است.</h2>
                                {
                                    displayCart.soldOut.map((item,index)=>{
                                        return <SoldOutItem data={item} key={index} />
                                    })
                                }
                            </div>
                            :""}
                    </div>
                }
            </div>
            {displayCart.number > 0 ? <div className="flex flexCol leftSideDiv">
                <div className="priceReportDiv">
                    <p className="faFont textSize4 textClr3 flex justifyBetween">قیمت محصولات : <span>{toPer(displayCart.totalPrice.toLocaleString())} تومان</span></p> 
                    <p className="faFont textSize4 textClr3 flex justifyBetween">تخفیف : <span>{toPer((displayCart.totalPrice - displayCart.finalPrice).toLocaleString())} تومان</span></p> 
                    <p className="faFont textSize4 textClr3 flex justifyBetween">هزینه ارسال : <span>{toPer((+displayCart.tcPrice).toLocaleString())} تومان</span></p> 
                    <p className="faFont textSize4 textClr3 flex justifyBetween">قیمت نهایی : <span>{toPer((+displayCart.finalPrice + +displayCart.tcPrice).toLocaleString())} تومان</span></p>    
                    <div className="confirmCartDiv flex flexCol">
                        <button className="submitBtn faFont textSize4 flex flexCenter" onClick={()=>props.changeMenuHandler("userInfo")}><span >ثبت سفارش</span></button>
                    </div>
                </div>
            </div> :""}
        </div>
    }
    </section>
}