import React from "react";
import "../style/offers.css";
import ProductItem from "./ProductItem";
import { Link } from "react-router-dom";
import { mouseIsDown, mouseLeave, mouseMove, mouseUp } from "./Methods";
export default function Offers(prop){
    const offersData = prop.offers;
    return <section className="flex flexCol rtl offersSection">
        <h2 className="titrFont textSize2 offerTitle textClr1">پیشنهادات شگفت انگیز</h2>
        <div className="flex offersList gap15 hideScrollBar" onMouseDown={(e)=>mouseIsDown(e,"offersList")} onMouseMove={(e)=>mouseMove(e,"offersList")} onMouseLeave={(e)=>mouseLeave(e,"offersList")} onMouseUp={(e)=>mouseUp(e,"offersList")}>
        {
            offersData.map((item,index)=>{
                return <ProductItem details = {item} key={index}/>
            })
        }
        <Link className="seeAllOffers faFont textSize4 textClr3" to="/category/?q=offers">مشاهده همه</Link>
        </div>
    </section>
}