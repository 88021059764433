import React, { useEffect, useState } from "react";
import "../style/tracking.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "./CustomInput";
import Loading from "./Loading";
import { showMsg, timeInShamsi, toPer } from "./Methods";
export default function Tracking(){
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [tcInput , setTcInput] = useState(code?code:"");
    const [result , setResult] = useState("");
    
    const navigate = useNavigate();
    useEffect(()=>{
        document.title = "پیگیری سفارش";
    },[])
    useEffect(()=>{
        setResult("");
        if(code){
            const data = new FormData();
            data.append("code",code);
            fetch("/api2/tracking/", {
                method: "POST",
                body: data,
            })
            .then(res=>{
                if(res.status === 200){
                    return res.json();
                }else{
                    throw Error("عدم دسترسی به سرور");
                }
            })
            .then(result=>{
                if(result.error){
                    throw new Error(result.msg)
                }
                if(!result.found){
                    setResult({found:false});
                }else{
                    setResult({
                        found:true,
                        name:result.name,
                        price:result.price,
                        state:result.state,
                        code : result.code,
                        date : result.date,
                        trackingPost : result.trackingPost,
                        description : result.description,
                        info: result.info,
                    });
                }
                
            })
            .catch(err=>{
                setResult({found:false});
                showMsg(err.msg);
            });
        }
    },[code]);
    function changeTcInputHandler(prop,value){
        setTcInput(value);
    }
    function confirmTcHandler(){
        if(tcInput){
            navigate(`/tracking/?code=${tcInput}`);
        }
    }
    return <section className="container rtl">
        <h1 className="titrFont textSize2 textClr3 mar15 trackingTitle flex flexCenter"><span>پیگیری سفارش</span></h1>
        <div className={code ? " trackingGridContainer grid" : "trackingFlexContainer flex flexCol"}>
            <div className="flex flexCol rightSideDiv">
                <div className="flex flexCol gap10 tcInputDiv">
                    <CustomInput name="tc" complete={"tc"} type={"tc"} value={tcInput} placeholder={"abcd1234"} onChange={changeTcInputHandler} label={"کد رهگیری خود را اینجا وارد کنید"} hasToggleBtn={false} icon="tc" isEnglish={false}/>
                    {tcInput ? 
                    <button className="submitTc faFont textSize4 textClr3" onClick={confirmTcHandler}>تایید</button>
                    :""}
                </div>
            </div>
            {
                code ?
                result ? 
                <div className="flex flexCol leftSideDiv">
                    {
                        result.found ? <div className="flex flexCol resultDiv">
                            <h2 className="titrFont textSize2 textClr3">نتیجه جستجو :</h2>
                            <div className="flex flexCol resultTable mar15">
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">وضعیت سفارش :</p><p className="faFont textSize5 textClr3">{ toPer(result.state.toLocaleString())}</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">عنوان :</p><p className="faFont textSize5 textClr3">{ toPer(result.name.toLocaleString())}</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">جمع کل :</p><p className="faFont textSize5 textClr3">{ toPer(result.price.toLocaleString())} تومان</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">کد پیگیری :</p><p className="faFont textSize5 textClr3">{result.code}</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">تاریخ ثبت سفارش :</p><p className="faFont textSize5 textClr3">{timeInShamsi(result.date)}</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">کد رهگیری پستی :</p><p className="faFont textSize5 textClr3 copyableText">{result.trackingPost}</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">توضیحات شما :</p><p className="faFont textSize5 textClr3">{toPer(result.description.toLocaleString())}</p>
                                </div>
                                <div className="flex alignCenter gap10 resultTableItem">
                                    <p className="faFont textSize6 textClr5">توضیحات فروشنده :</p><p className="faFont textSize5 textClr3">{ toPer(result.info.toLocaleString())}</p>
                                </div>
                            </div>
                        </div>
                        : 
                        <div className="flex flexCol noResultDiv gap10">
                            <div className="noResultImg"></div>
                            <h2 className="titrFont textSize2 textClr3">نتیجه ای با کد رهگیری داده شده یافت نشد .</h2>
                            <p className="faFont textSize5 textClr5">در صورتی که از کد رهگیری اطمینان دارید با پشیتبانی سایت تماس بگیرید.</p>
                        </div>
                    }
                </div>
                :
                <Loading />
                :
                ""
            }
        </div>
    </section> 
}