import React, { useEffect, useState } from "react";
import { shortText, showMsg, toPer } from "./Methods";
import { Link } from "react-router-dom";
import Loading from "./Loading";
export default function UserOrders(props){
    const usn = props.usn;
    const psw = props.psw;
    const [isLoading,setIsLoading] = useState(true);
    const [ordersList , setOrdersList ] = useState("");

    useEffect(()=>{
        fetchOrders();
     },[]); 

    function fetchOrders(){
        const data = new FormData();
        data.append("usn",usn);
        data.append("psw",psw);
        fetch("/api2/userOrders/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg);
            }
            setOrdersList(JSON.parse(result.list));
            setIsLoading(false);
        })
        .catch(err=>{
            showMsg(err.message);
        });
    }
    if(isLoading){
        return <Loading />;
    }
    return <div className="flex flexCol ordersSection mar15">
        <h2 className="titrFont textSize2 textClr3 ordersTitle">سفارش های من</h2>
        <div className="flex flexCol ordersList mar15 gap10">
            {
                !ordersList || ordersList.length === 0 ? "" : ordersList.map((item,index)=>{
                    return <div className="grid orderItem" key={index}>
                        <img src={item.img} alt={shortText(item.name,30)} className="orderImg fitImage"/>
                        <div className="flex flexCol orderItemDetails gap5">
                            <h2 className="titrFont textSize2 textClr3">{shortText(toPer(item.name.toLocaleString()),30) }</h2>
                            <p className="faFont flex alignCenter gap5"><span className="textSize5 textClr5">قیمت</span><span className="textSize5 textClr5">:</span><span className="textSize5 textClr5">{toPer(item.price.toLocaleString())} تومان</span></p>
                            <p className="faFont flex alignCenter gap5"><span className="textSize5 textClr5">وضعیت</span><span className="textSize5 textClr5">:</span><span className="textSize5 textClr5">{toPer(item.state.toLocaleString())}</span></p>
                            <p className="faFont flex alignCenter gap5"><span className="textSize5 textClr5">کد پیگیری</span><span className="textSize5 textClr5">:</span><span className="textSize5 textClr5 copyableText">{item.code}</span></p>
                        </div>
                        <Link to={`/purchaseDetails/?q=${item.code}`} className="iconText" ><span className="hideText details">details</span><p className="faFont textSize4 textClr3">جزییات</p></Link>            
                    </div>
                })
            }
        </div>
    </div>
}