import React, { useEffect, useState } from "react";
import { scrollToTop, showMsg } from "./Methods";
import { useNavigate } from "react-router-dom";
import CustomInput from "./CustomInput";
import Loading from "./Loading";
export default function UserEdit(props){
    const usn = props.usn;
    const psw = props.psw;
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(true);

    const [userData , setUserData ] = useState({
        firstName:"",
        lastName:"",
        email:"",
        address:"",
        phone:"",
        postCode:"",
        submitingData : false
    });
    useEffect(()=>{
        fetchUserInfo();
    },[]);
    function setUserInfoHandler(prop, value){
        setUserData({ ...userData, [prop]: value });
    }
    function fetchUserInfo(){
        const data = new FormData();
        data.append("usn",usn);
        data.append("psw",psw);
        fetch("/api2/userInfo/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            setUserData({ firstName:result.firstName,
            lastName:result.lastName,
            email:usn,
            address:result.address,
            phone:result.phone,
            postCode:result.postcode,
            psw:psw,
            submitingData : false});
            setIsLoading(false);
        })
        .catch(err=>{
            showMsg(err.message);
        });
    }
    function saveUserData(){
        if(!userData.firstName){
            showMsg("لطفا نام خود را وارد کنید.");
            return;
        }
        if(!userData.lastName){
            showMsg("لطفا نام خانوادگی خود را وارد کنید.");
            return;
        }
        if(!userData.phone){
            showMsg("لطفا شماره خود را وارد کنید.");
            return;
        }
        if(!userData.address){
            showMsg("لطفا آدرس خود را وارد کنید.");
            return;
        }
        if(!userData.postCode){
            showMsg("لطفا کد پستی خود را وارد کنید.");
            return;
        }
        setUserData(userData=>({...userData,submitingData:true}));
        const data = new FormData();
        data.append("usn",usn);
        data.append("psw",psw);
        data.append("firstName",userData.firstName);
        data.append("lastName",userData.lastName);
        data.append("phone",userData.phone);
        data.append("address",userData.address);
        data.append("post",userData.postCode);
        fetch("/api2/editUser/", {
            method: "POST",
            body: data,
        })
        .then(res=>{
            if(res.status === 200){
                return res.json();
            }else{
                throw Error("عدم دسترسی به سرور");
            }
        })
        .then(result=>{
            if(result.error){
                throw new Error(result.msg)
            }
            showMsg("اطلاعات با موفقیت به روز شد.");
            scrollToTop();
            navigate("/user");
        })
        .catch(err=>{
            setUserData(userData=>({...userData,submitingData:false}));
            showMsg(err.message)
        });
    }
    if(isLoading){
        return <Loading />;
    }
    return <div className="flex flexCol editProfileSection mar15 gap20">
        <h2 className="titrFont textSize2 textClr3 ordersTitle">ویرایش اطلاعات</h2>
        <CustomInput name="email" complete={"email"} type={"email"} value={userData.email} placeholder={"example@gmail.com"} onChange={setUserInfoHandler} label={"ایمیل"} hasToggleBtn={false} icon="email" isEnglish={true} readOnly={true}/>
        <CustomInput name="firstName" complete={"firstName"} type={"text"} value={userData.firstName} placeholder={"رها"} onChange={setUserInfoHandler} label={"نام ( به فارسی )"} hasToggleBtn={false} icon="person" isEnglish={false}/>
        <CustomInput name="lastName" complete={"lastName"} type={"text"} value={userData.lastName} placeholder={"ایرانی"} onChange={setUserInfoHandler} label={"نام خانوادگی ( به فارسی )"} hasToggleBtn={false} icon="person" isEnglish={false}/>
        <CustomInput name="phone" complete={"phone"} type={"number"} value={userData.phone} placeholder={"۰۹۱۲۰۰۰۰۰۰۰"} onChange={setUserInfoHandler} label={"شماره تماس"} hasToggleBtn={false} icon="phone" isEnglish={false}/>
        <CustomInput name="address" complete={"address"} type={"text"} value={userData.address} placeholder={"مشهد - احمد آباد"} onChange={setUserInfoHandler} label={"آدرس"} hasToggleBtn={false} icon="address" isEnglish={false}/>
        <CustomInput name="postCode" complete={"postCode"} type={"number"} value={userData.postCode} placeholder={"۱۲۳۴۵۶۷۸۹"} onChange={setUserInfoHandler} label={"کد پستی"} hasToggleBtn={false} icon="postCode" isEnglish={false}/>
        {
            !userData.submitingData  ? <button className="submitBtn faFont textSize4" onClick={saveUserData}>ثبت تغییرات</button>
            : <div className="loadBlueBtn flex flexCenter"><span></span></div>
        }
    </div>
}